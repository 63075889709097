.telaHomeAreaparceirosChild {
  position: absolute;
  top: 49px;
  left: 1085px;
  border-radius: var(--br-3xs);
  background-color: var(--branco-c);
  width: 797px;
  height: 989px;
}
.minhasVendas {
  position: absolute;
  top: 83px;
  left: 1169px;
  font-size: var(--font-size-5xl);
  line-height: 20.72px;
  font-weight: 600;
  color: var(--preto-padro);
}
.fundosAReceber,
.recebidoHoje {
  position: absolute;
  left: 428px;
  line-height: 20.72px;
}
.fundosAReceber {
  top: 66px;
  font-size: var(--font-size-5xl);
  font-weight: 600;
  color: var(--preto-padro);
}
.recebidoHoje {
  top: 212px;
  font-size: var(--font-size-xl);
}
.telaHomeAreaparceirosInner,
.telaHomeAreaparceirosItem {
  position: absolute;
  top: 304.5px;
  left: 427.5px;
  border-top: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 547px;
  height: 1px;
}
.telaHomeAreaparceirosInner {
  top: 738.5px;
}
.miniDashboard {
  position: absolute;
  top: 353px;
  left: 433px;
  font-size: var(--font-size-5xl);
  line-height: 20.72px;
  font-weight: 600;
  color: var(--preto-padro);
}
.jan {
  position: absolute;
  left: 0;
  width: 18.77px;
}
.feb,
.jan,
.mar {
  top: 0;
  display: inline-block;
}
.feb {
  position: absolute;
  left: 42.24px;
  width: 19.56px;
}
.mar {
  left: 84.48px;
  width: 20.34px;
}
.apr,
.mar,
.may {
  position: absolute;
}
.apr {
  top: 0;
  left: 127.5px;
  display: inline-block;
  width: 18.77px;
}
.may {
  left: 168.96px;
  width: 21.9px;
}
.jul,
.jun,
.may {
  top: 0;
  display: inline-block;
}
.jun {
  position: absolute;
  left: 210.42px;
  width: 19.56px;
}
.jul {
  left: 252.66px;
  width: 15.64px;
}
.aug,
.jul,
.sep {
  position: absolute;
}
.aug {
  top: 0;
  left: 290.99px;
  display: inline-block;
  width: 21.12px;
}
.sep {
  left: 334.8px;
  width: 20.34px;
}
.nov,
.oct,
.sep {
  top: 0;
  display: inline-block;
}
.oct {
  position: absolute;
  left: 378.6px;
  width: 18.77px;
}
.nov {
  left: 420.06px;
  width: 21.12px;
}
.dec,
.janParent,
.nov {
  position: absolute;
}
.dec {
  top: 0;
  left: 463.87px;
  display: inline-block;
  width: 21.12px;
}
.janParent {
  top: 249px;
  left: 51.63px;
  width: 484.99px;
  height: 12px;
}
.groupChild,
.groupItem {
  position: absolute;
  top: 169.5px;
  left: 40.18px;
  border-top: 1px dashed var(--branco-c);
  box-sizing: border-box;
  width: 506.32px;
  height: 1px;
}
.groupItem {
  top: 225.5px;
  border-top: 1px solid var(--branco-c);
}
.k,
.k1 {
  position: absolute;
  left: 0;
  width: 25px;
}
.k {
  top: 106px;
  display: inline-block;
}
.k1 {
  top: 53px;
}
.k1,
.k2,
.k3 {
  display: inline-block;
}
.k2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
}
.k3 {
  top: 162px;
  left: 3px;
  width: 22px;
}
.div,
.k3,
.kParent {
  position: absolute;
}
.div {
  top: 218px;
  left: 16px;
  display: inline-block;
  width: 9px;
}
.kParent {
  top: 0;
  left: 0;
  width: 25px;
  height: 231px;
}
.groupChild1,
.groupInner,
.lineDiv {
  position: absolute;
  top: 1.5px;
  left: 40.18px;
  border-top: 1px dashed var(--branco-c);
  box-sizing: border-box;
  width: 506.32px;
  height: 1px;
}
.groupChild1,
.lineDiv {
  top: 58.5px;
}
.groupChild1 {
  top: 113.5px;
}
.groupChild10,
.groupChild11,
.groupChild12,
.groupChild2,
.groupChild3,
.groupChild4,
.groupChild5,
.groupChild6,
.groupChild7,
.groupChild8,
.groupChild9,
.rectangleDiv {
  position: absolute;
  top: 68.16px;
  left: 0;
  border-radius: var(--br-7xs);
  background: linear-gradient(
    180deg,
    rgba(73, 73, 73, 0.35),
    rgba(61, 61, 61, 0.35) 99.98%,
    rgba(61, 61, 61, 0.35) 99.99%
  );
  width: 14.86px;
  height: 95.42px;
}
.groupChild10,
.groupChild11,
.groupChild12,
.groupChild2,
.groupChild3,
.groupChild4,
.groupChild5,
.groupChild6,
.groupChild7,
.groupChild8,
.groupChild9 {
  top: 42.84px;
  left: 82.92px;
  height: 120.74px;
}
.groupChild10,
.groupChild11,
.groupChild12,
.groupChild3,
.groupChild4,
.groupChild5,
.groupChild6,
.groupChild7,
.groupChild8,
.groupChild9 {
  top: 68.16px;
  left: 125.94px;
  height: 95.42px;
}
.groupChild10,
.groupChild11,
.groupChild12,
.groupChild4,
.groupChild5,
.groupChild6,
.groupChild7,
.groupChild8,
.groupChild9 {
  top: 87.63px;
  left: 168.96px;
  height: 75.95px;
}
.groupChild10,
.groupChild11,
.groupChild12,
.groupChild5,
.groupChild6,
.groupChild7,
.groupChild8,
.groupChild9 {
  top: 60.37px;
  left: 210.42px;
  height: 103.21px;
}
.groupChild10,
.groupChild11,
.groupChild12,
.groupChild6,
.groupChild7,
.groupChild8,
.groupChild9 {
  top: 87.63px;
  left: 290.21px;
  height: 75.95px;
}
.groupChild10,
.groupChild11,
.groupChild12,
.groupChild7,
.groupChild8,
.groupChild9 {
  top: 105.16px;
  left: 376.26px;
  height: 58.42px;
}
.groupChild10,
.groupChild11,
.groupChild12,
.groupChild8,
.groupChild9 {
  top: 15.58px;
  left: 250.32px;
  height: 148px;
}
.groupChild10,
.groupChild11,
.groupChild12,
.groupChild9 {
  top: 0;
  left: 334.01px;
  height: 163.58px;
}
.groupChild10,
.groupChild11,
.groupChild12 {
  top: 15.58px;
  left: 419.28px;
  height: 148px;
}
.groupChild11,
.groupChild12 {
  top: 60.53px;
  left: 462.3px;
  height: 103.21px;
}
.groupChild12 {
  top: 105.16px;
  left: 40.68px;
  height: 58.42px;
}
.rectangleParent {
  position: absolute;
  top: 56.47px;
  left: 0;
  width: 477.16px;
  height: 163.74px;
}
.groupChild13,
.groupChild14,
.groupChild15,
.groupChild16 {
  position: absolute;
  top: 54.53px;
  left: 0;
  border-radius: var(--br-7xs);
  background-color: var(--color-blanchedalmond-100);
  width: 14.86px;
  height: 165.53px;
}
.groupChild14,
.groupChild15,
.groupChild16 {
  top: 23.37px;
  left: 40.68px;
  height: 196.68px;
}
.groupChild15,
.groupChild16 {
  top: 87.63px;
  left: 82.92px;
  height: 132.42px;
}
.groupChild16 {
  top: 35.05px;
  left: 125.94px;
  height: 185px;
}
.groupChild17,
.groupChild18,
.groupChild19,
.groupChild20,
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24 {
  position: absolute;
  top: 54.53px;
  left: 210.42px;
  border-radius: var(--br-7xs);
  background-color: var(--color-blanchedalmond-100);
  width: 14.86px;
  height: 165.53px;
}
.groupChild18,
.groupChild19,
.groupChild20,
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24 {
  top: 66.21px;
  left: 290.21px;
  height: 153.84px;
}
.groupChild19,
.groupChild20,
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24 {
  top: 42.84px;
  left: 376.26px;
  height: 177.21px;
}
.groupChild20,
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24 {
  top: 66.21px;
  left: 419.28px;
  height: 153.84px;
}
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24 {
  top: 0;
  left: 462.3px;
  height: 220.05px;
}
.groupChild22,
.groupChild23,
.groupChild24 {
  top: 83.74px;
  left: 334.01px;
  height: 136.32px;
}
.groupChild23,
.groupChild24 {
  top: 136.32px;
  left: 250.32px;
  height: 83.74px;
}
.groupChild24 {
  top: 9.74px;
  left: 168.96px;
  height: 210.32px;
}
.graph,
.groupContainer,
.groupDiv,
.rectangleGroup {
  position: absolute;
  top: 0;
  left: 0;
  width: 477.16px;
  height: 220.05px;
}
.graph,
.groupContainer,
.groupDiv {
  height: 220.21px;
}
.graph,
.groupContainer {
  top: 2px;
  left: 54.76px;
}
.groupContainer {
  top: 0;
  left: 0;
  width: 546px;
  height: 231px;
  text-align: right;
  font-size: var(--font-size-2xs);
}
.groupParent {
  position: absolute;
  top: 433px;
  left: 428px;
  width: 546px;
  height: 261px;
}
.incomeChild,
.msAtual {
  position: absolute;
  top: 0;
  left: 14px;
}
.incomeChild {
  top: 4px;
  left: 0;
  width: 8px;
  height: 8px;
}
.cost,
.income,
.incomeParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 77px;
  height: 16px;
}
.cost,
.incomeParent {
  left: 92px;
  width: 98px;
}
.incomeParent {
  top: 17px;
  left: 0;
  width: 190px;
}
.vendasMensais {
  position: absolute;
  top: 0;
  left: 64px;
  font-size: var(--ttulos-cabealho-size);
  line-height: 20.72px;
  font-weight: 600;
  color: var(--cinza-padro);
  text-align: right;
}
.groupParent1 {
  position: absolute;
  top: 345px;
  left: 785px;
  width: 190px;
  height: 33px;
  font-size: var(--font-size-smi);
  color: var(--nd-text-color);
}
.vectorIcon {
  position: absolute;
  top: 81.75px;
  left: 1367.75px;
  width: 24.5px;
  height: 20.5px;
}
.variaoMiniextratoChild {
  position: absolute;
  height: 0.23%;
  width: 99.05%;
  top: 3.74%;
  right: 1.11%;
  bottom: 96.03%;
  left: -0.16%;
  border-top: 2px solid var(--cinza-padro);
  box-sizing: border-box;
}
.hoje {
  position: absolute;
  height: 29.92%;
  width: 66.04%;
  top: 0;
  left: 0;
  line-height: 20.72px;
  display: flex;
  align-items: center;
}
.groupChild25 {
  position: absolute;
  height: 6.06%;
  width: 103.77%;
  top: 96.97%;
  right: -1.89%;
  bottom: -3.03%;
  left: -1.89%;
  border-top: 2px solid var(--preto-padro);
  box-sizing: border-box;
}
.hojeParent {
  position: absolute;
  height: 3.86%;
  width: 8.41%;
  top: 0;
  right: 91.59%;
  bottom: 96.14%;
  left: 0;
  color: var(--preto-padro);
}
.abril2023,
.div1,
.r,
.totalDoPerodo {
  position: absolute;
  line-height: 20.72px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.abril2023 {
  height: 1.15%;
  width: 17.3%;
  top: 0;
  left: 81.59%;
  color: var(--preto-c);
  text-align: right;
}
.div1,
.r,
.totalDoPerodo {
  left: 0;
}
.totalDoPerodo {
  height: 22.22%;
  width: 52.01%;
  top: 0;
  font-size: var(--font-size-xl);
}
.div1,
.r {
  height: 44.44%;
  width: 17.03%;
  top: 55.56%;
}
.div1 {
  height: 43.41%;
  width: 81.42%;
  top: 56.07%;
  left: 18.58%;
}
.totalDoPerodoParent {
  position: absolute;
  height: 7.27%;
  width: 51.27%;
  top: 7.96%;
  right: 48.73%;
  bottom: 84.78%;
  left: 0;
  font-size: var(--font-size-21xl);
  color: var(--preto-c);
}
.dias {
  position: absolute;
  width: 76.83%;
  top: 0;
  left: 0;
  line-height: 20.72px;
  display: flex;
  align-items: center;
}
.frameChild {
  position: absolute;
  height: 6.06%;
  width: 70.96%;
  top: 96.97%;
  right: 30.26%;
  bottom: -3.03%;
  left: -1.22%;
  border-top: 2px solid var(--cinza-padro);
  box-sizing: border-box;
}
.dias1,
.diasParent {
  position: absolute;
  top: 0;
}
.diasParent {
  left: 78px;
  width: 82px;
  height: 33px;
}
.dias1 {
  width: 114.58%;
  left: 0;
  line-height: 20.72px;
  display: flex;
  align-items: center;
}
.frameItem {
  position: absolute;
  height: 6.06%;
  width: 170.95%;
  top: 96.97%;
  right: -69.85%;
  bottom: -3.03%;
  left: -1.1%;
  border-top: 2px solid var(--cinza-padro);
  box-sizing: border-box;
}
.diasGroup,
.variaoMiniextratoInner {
  position: absolute;
  top: 0;
  left: 0;
  width: 91px;
  height: 33px;
}
.variaoMiniextratoInner {
  left: 185px;
}
.cashbackDistribudo,
.div2 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 20.72px;
  font-weight: 600;
}
.div2 {
  left: 38px;
}
.rParent,
.vectorIcon1 {
  position: relative;
  width: 107px;
  height: 17px;
  flex-shrink: 0;
}
.vectorIcon1 {
  width: 12px;
  height: 12px;
}
.groupParent2 {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-8xs);
}
.frameWrapper {
  position: absolute;
  top: 20px;
  left: 0;
  width: 122px;
  height: 17px;
  font-size: var(--font-size-5xl);
  color: var(--verde);
}
.cashbackDistribudoParent {
  position: relative;
  width: 164px;
  height: 37px;
  flex-shrink: 0;
}
.frameDiv {
  position: absolute;
  height: 7.83%;
  width: 33.97%;
  top: 7.68%;
  right: 1.11%;
  bottom: 84.49%;
  left: 64.92%;
  border-radius: var(--br-3xs);
  background-color: var(--branco);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-mini) var(--padding-6xl);
  box-sizing: border-box;
  align-items: flex-end;
  justify-content: center;
}
.cashback,
.formaDePagamento,
.horrio,
.statusunidade,
.valortaxa {
  position: absolute;
  height: 100%;
  width: 9.51%;
  top: 0;
  left: 0;
  line-height: 20.72px;
  display: flex;
  align-items: center;
}
.cashback,
.formaDePagamento,
.statusunidade,
.valortaxa {
  width: 28.01%;
  left: 13.75%;
}
.cashback,
.statusunidade,
.valortaxa {
  width: 13.07%;
  left: 70.12%;
}
.cashback,
.statusunidade {
  width: 12.56%;
  left: 87.44%;
}
.statusunidade {
  width: 19.86%;
  left: 46.01%;
}
.horrioParent {
  position: absolute;
  height: 1.15%;
  width: 93.49%;
  top: 37.83%;
  right: 6.51%;
  bottom: 61.01%;
  left: 0;
}
.groupIcon {
  position: absolute;
  top: 8px;
  left: 627px;
  width: 3px;
  height: 13px;
}
.div3 {
  top: 1px;
  left: 0;
  font-weight: 600;
}
.div3,
.r756,
.xuliz {
  position: absolute;
  line-height: 20.72px;
}
.xuliz {
  top: 1.75px;
  left: 81px;
  font-weight: 600;
}
.r756 {
  top: 20px;
  left: 425px;
  color: var(--cinza-padro);
}
.div4,
.r21600 {
  position: absolute;
  top: 1px;
}
.r21600 {
  left: 425px;
  line-height: 20.72px;
  font-weight: 600;
}
.div4 {
  left: 413px;
  color: var(--verde);
}
.div4,
.div5,
.r2592 {
  line-height: 20.72px;
  font-weight: 600;
}
.div5 {
  position: absolute;
  top: 20px;
  left: 414px;
  color: var(--vermelho);
}
.r2592 {
  position: relative;
}
.vectorIcon2 {
  position: relative;
  width: 11px;
  height: 10px;
  flex-shrink: 0;
}
.r2592Parent {
  position: absolute;
  top: 0;
  left: 515px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-8xs);
}
.completado,
.fcamor {
  position: absolute;
  line-height: 20.72px;
}
.fcamor {
  top: 20px;
  left: 271px;
  color: var(--cinza-padro);
}
.completado {
  top: 14%;
  left: 18.4%;
  font-weight: 600;
}
.vectorIcon3 {
  position: absolute;
  height: 106%;
  width: 13.76%;
  top: 0;
  right: 86.97%;
  bottom: -6%;
  left: -0.74%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.variaoStatus {
  position: absolute;
  top: 0;
  left: 271px;
  width: 101.72px;
  height: 12.5px;
  color: var(--verde);
}
.groupWrapper,
.parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 581px;
  height: 29px;
}
.groupWrapper {
  width: 630px;
}
.vectorIcon4 {
  position: relative;
  width: 11px;
  height: 11px;
  flex-shrink: 0;
}
.vectorIcon5 {
  position: absolute;
  height: 112%;
  width: 13.76%;
  top: -6%;
  right: 86.97%;
  bottom: -6%;
  left: -0.74%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.group {
  position: absolute;
  top: 0;
  left: 0;
  width: 576px;
  height: 29px;
}
.container,
.groupFrame,
.groupWrapper1 {
  position: absolute;
  top: 59px;
  left: 0;
  width: 630px;
  height: 29px;
}
.container,
.groupWrapper1 {
  top: 0;
  width: 584px;
}
.groupWrapper1 {
  top: 118px;
  width: 630px;
}
.r108 {
  position: absolute;
  top: 20px;
  left: 425px;
  text-decoration: line-through;
  line-height: 20.72px;
  color: var(--cinza-padro);
}
.div13,
.r432 {
  line-height: 20.72px;
  font-weight: 600;
}
.div13 {
  position: absolute;
  top: 1px;
  left: 413px;
  color: var(--vermelho);
}
.r432 {
  position: relative;
  text-decoration: line-through;
}
.vectorIcon9 {
  position: absolute;
  height: 112%;
  width: 13.76%;
  top: -6%;
  right: 87.4%;
  bottom: -6%;
  left: -1.17%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.variaoStatus3 {
  position: absolute;
  top: 0;
  left: 271px;
  width: 101.72px;
  height: 12.5px;
  color: var(--vermelho);
}
.parent1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 575px;
  height: 29px;
}
.groupWrapper2,
.groupWrapper3,
.groupWrapper4,
.parent3 {
  position: absolute;
  top: 177px;
  left: 0;
  width: 630px;
  height: 29px;
}
.groupWrapper3,
.groupWrapper4,
.parent3 {
  top: 236px;
}
.groupWrapper4,
.parent3 {
  top: 0;
  width: 574px;
}
.groupWrapper4 {
  top: 295px;
  width: 630px;
}
.div22,
.div23,
.r52300 {
  position: absolute;
  top: 1px;
  line-height: 20.72px;
  font-weight: 600;
}
.r52300 {
  left: 425px;
  text-decoration: line-through;
}
.div22,
.div23 {
  left: 413px;
  color: var(--color-darkgoldenrod);
}
.div23 {
  top: 20px;
  left: 414px;
}
.vectorIcon16 {
  position: absolute;
  height: 112%;
  width: 13.76%;
  top: -6%;
  right: 86.7%;
  bottom: -6%;
  left: -0.46%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.groupChild32,
.groupWrapper5,
.parent4 {
  position: absolute;
  top: 0;
  left: 0;
  width: 582px;
  height: 29px;
}
.groupChild32,
.groupWrapper5 {
  top: 354px;
  width: 630px;
}
.groupChild32 {
  top: -0.3px;
  left: -0.3px;
  border-top: 0.6px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 630.6px;
  height: 0.6px;
}
.groupChild33,
.groupChild34,
.groupChild35,
.groupChild36,
.groupChild37,
.groupChild38 {
  position: absolute;
  top: 58.75px;
  left: -0.25px;
  border-top: 0.5px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 630.5px;
  height: 0.5px;
}
.groupChild34,
.groupChild35,
.groupChild36,
.groupChild37,
.groupChild38 {
  top: 117.75px;
}
.groupChild35,
.groupChild36,
.groupChild37,
.groupChild38 {
  top: 176.75px;
}
.groupChild36,
.groupChild37,
.groupChild38 {
  top: 235.75px;
}
.groupChild37,
.groupChild38 {
  top: 294.75px;
}
.groupChild38 {
  top: 353.75px;
}
.groupChild40 {
  position: absolute;
  top: 480px;
  left: 627px;
  width: 3px;
  height: 13px;
}
.vectorIcon20 {
  position: relative;
  width: 11px;
  height: 9px;
  flex-shrink: 0;
}
.vectorIcon21 {
  position: absolute;
  height: 100%;
  width: 13.76%;
  top: -6%;
  right: 86.97%;
  bottom: 6%;
  left: -0.74%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vectorIcon22 {
  position: relative;
  width: 9px;
  height: 9px;
  flex-shrink: 0;
}
.vectorIcon23,
.vectorIcon25,
.vectorIcon32 {
  position: absolute;
  height: 100%;
  width: 12.29%;
  top: 0;
  right: 87.71%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vectorIcon25,
.vectorIcon32 {
  right: 88.14%;
  left: -0.43%;
}
.vectorIcon32 {
  right: 87.43%;
  left: 0.28%;
}
.groupChild46 {
  position: absolute;
  top: 43.7px;
  left: -0.3px;
  border-top: 0.6px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 630.6px;
  height: 0.6px;
}
.groupChild47,
.groupChild48,
.groupChild49,
.groupChild50,
.groupChild51,
.groupChild52 {
  position: absolute;
  top: 102.75px;
  left: -0.25px;
  border-top: 0.5px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 630.5px;
  height: 0.5px;
}
.groupChild48,
.groupChild49,
.groupChild50,
.groupChild51,
.groupChild52 {
  top: 161.75px;
}
.groupChild49,
.groupChild50,
.groupChild51,
.groupChild52 {
  top: 220.75px;
}
.groupChild50,
.groupChild51,
.groupChild52 {
  top: 279.75px;
}
.groupChild51,
.groupChild52 {
  top: 338.75px;
}
.groupChild52 {
  top: 397.75px;
}
.groupParent11,
.lineParent {
  position: absolute;
  top: 369px;
  left: 0;
  width: 630px;
  height: 398px;
}
.lineParent {
  top: 44px;
  height: 767px;
}
.groupParent3,
.variaoMiniextratoInner1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 630px;
  height: 811px;
}
.variaoMiniextratoInner1 {
  top: 374px;
  height: 478px;
  overflow-y: auto;
  font-size: var(--font-size-smi);
  color: var(--preto-c);
}
.crditoWrapper,
.filtrarTransaes {
  position: absolute;
  display: flex;
  align-items: center;
}
.filtrarTransaes {
  height: 1.61%;
  width: 28.41%;
  top: 24.68%;
  left: 0;
  font-size: var(--font-size-xl);
  line-height: 20.72px;
  font-weight: 600;
  color: var(--preto-padro);
}
.crditoWrapper {
  top: calc(50% - 181px);
  left: calc(50% - 313px);
  border-radius: var(--br-3xs);
  border: 1px solid var(--cinza-padro);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-3xs);
  justify-content: center;
}
.dbito {
  position: relative;
  line-height: 20.72px;
}
.dbitoWrapper,
.pixWrapper,
.saldoXulizCrditoWrapper,
.saldoXulizWrapper {
  position: absolute;
  top: calc(50% - 181px);
  left: calc(50% - 227px);
  border-radius: var(--br-3xs);
  border: 1px solid var(--cinza-padro);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  align-items: center;
  justify-content: center;
}
.pixWrapper,
.saldoXulizCrditoWrapper,
.saldoXulizWrapper {
  left: calc(50% - 146px);
}
.saldoXulizCrditoWrapper,
.saldoXulizWrapper {
  left: calc(50% - 93px);
}
.saldoXulizCrditoWrapper {
  left: calc(50% + 18px);
}
.variaoMiniextrato {
  position: absolute;
  top: 145px;
  left: 1171px;
  width: 630px;
  height: 856px;
  font-size: var(--ttulos-cabealho-size);
}
.div45,
.r2 {
  top: 85.53%;
  line-height: 20.72px;
  font-weight: 600;
}
.r2 {
  position: absolute;
  left: 0;
}
.div45 {
  left: 11.46%;
}
.div45,
.div46,
.rIcon {
  position: absolute;
}
.rIcon {
  height: 100%;
  width: 16.99%;
  top: 0;
  right: 83.01%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.div46 {
  top: 12.77%;
  left: 21.8%;
  line-height: 20.72px;
  font-weight: 600;
}
.privarNmerosInner,
.rGroup,
.vectorIcon34 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.privarNmerosInner,
.vectorIcon34 {
  width: 88.21%;
  right: 11.79%;
}
.vectorIcon34 {
  height: 31.91%;
  width: 5.09%;
  top: 39.01%;
  right: -0.18%;
  bottom: 29.08%;
  left: 95.08%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.privarNmeros {
  position: absolute;
  height: 37.11%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 62.89%;
  left: 0;
  font-size: var(--font-size-41xl);
}
.privacidadeNmeros {
  position: absolute;
  top: 111px;
  left: 428px;
  width: 471.16px;
  height: 152px;
  font-size: var(--font-size-13xl);
  color: var(--preto-c);
}
.telaHomeAreaparceirosChild1,
.telaHomeAreaparceirosChild2,
.telaHomeAreaparceirosChild3,
.telaHomeAreaparceirosChild4 {
  position: absolute;
  top: 784px;
  left: 427px;
  border-radius: var(--br-3xs);
  background-color: var(--branco-c);
  width: 266px;
  height: 120px;
}
.telaHomeAreaparceirosChild2,
.telaHomeAreaparceirosChild3,
.telaHomeAreaparceirosChild4 {
  top: 918px;
}
.telaHomeAreaparceirosChild3,
.telaHomeAreaparceirosChild4 {
  top: 784px;
  left: 708px;
}
.telaHomeAreaparceirosChild4 {
  top: 918px;
}
.div47,
.ticketMdio {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 20.72px;
}
.div47 {
  left: 32px;
  font-weight: 600;
}
.rContainer {
  position: absolute;
  top: 39px;
  left: 0;
  width: 95px;
  height: 14px;
  color: var(--preto-padro);
}
.vectorIcon35 {
  position: absolute;
  top: 1px;
  left: 51px;
  width: 11px;
  height: 11px;
}
.groupWrapper13,
.parent12 {
  position: absolute;
  top: 0;
  left: 0;
  width: 61px;
  height: 14px;
}
.groupWrapper13 {
  top: 39px;
  left: 155px;
  color: var(--verde);
}
.ticketMdioParent {
  position: absolute;
  top: 818px;
  left: 452px;
  width: 216px;
  height: 53px;
  font-size: var(--font-size-xl);
}
.wrapper {
  position: absolute;
  top: 39px;
  left: 0;
  width: 25px;
  height: 14px;
  color: var(--preto-padro);
}
.vectorIcon36 {
  position: absolute;
  top: 1px;
  left: 43px;
  width: 11px;
  height: 11px;
}
.groupWrapper14,
.parent13 {
  position: absolute;
  top: 0;
  left: 0;
  width: 53px;
  height: 14px;
}
.groupWrapper14 {
  top: 39px;
  left: 163px;
  text-align: right;
  color: var(--verde);
}
.transaoAppParent {
  position: absolute;
  top: 818px;
  left: 733px;
  width: 216px;
  height: 53px;
  font-size: var(--font-size-xl);
}
.maisVendido {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 20.72px;
  display: flex;
  align-items: center;
  width: 209px;
}
.caneca250ml,
.caneca250mlWrapper {
  position: absolute;
  left: 0;
  width: 209px;
  height: 14px;
}
.caneca250ml {
  top: 0;
  line-height: 20.72px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.caneca250mlWrapper {
  top: 39px;
  color: var(--preto-padro);
}
.groupParent19,
.maisVendidoParent,
.top1ColaboradorParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 209px;
  height: 53px;
}
.groupParent19,
.top1ColaboradorParent {
  left: 284px;
}
.groupParent19 {
  top: 952px;
  left: 452px;
  width: 493px;
  font-size: var(--font-size-xl);
}
.telaHomeAreaparceirosChild5 {
  position: absolute;
  top: 0;
  left: -1px;
  background-color: var(--preto-padro);
  width: 319px;
  height: 1080px;
}
.vectorIcon37 {
  position: absolute;
  top: -0.75px;
  left: -0.75px;
  width: 16.5px;
  height: 16.5px;
}
.configuraes {
  position: absolute;
  top: 0;
  left: 21px;
  line-height: 15px;
  font-weight: 600;
}
.vectorParent {
  position: absolute;
  top: 893px;
  left: 62px;
  width: 96px;
  height: 15px;
  color: var(--branco);
}
.closeTracedIcon {
  position: absolute;
  height: 100%;
  width: 36.52%;
  top: 0;
  right: 63.48%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.closeTracedParent {
  position: absolute;
  height: 1.39%;
  width: 2.14%;
  top: 85.46%;
  right: 94.64%;
  bottom: 13.15%;
  left: 3.23%;
  cursor: pointer;
  color: var(--branco);
}
.vectorIcon38 {
  position: absolute;
  top: 1.25px;
  left: -0.75px;
  width: 16.5px;
  height: 13.77px;
}
.vectorContainer,
.vectorGroup {
  position: absolute;
  top: 863px;
  left: 62px;
  width: 57px;
  height: 15px;
  color: var(--branco);
}
.vectorContainer {
  top: 833px;
  width: 106px;
}
.ellipseIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.feitoComAmor,
.feitocomamorxulizcombr {
  position: absolute;
  left: 50px;
  line-height: 15px;
}
.feitoComAmor {
  top: 5px;
  font-weight: 600;
}
.feitocomamorxulizcombr {
  top: 20px;
  font-size: var(--font-size-3xs);
}
.ellipseParent {
  position: absolute;
  top: 998px;
  left: 62px;
  width: 192px;
  height: 40px;
  font-size: var(--font-size-xs);
  color: var(--branco);
}
.groupChild53,
.lineIcon {
  position: absolute;
  top: 962.5px;
  left: 61.5px;
  width: 166px;
  height: 1px;
}
.groupChild53 {
  height: 13.25%;
  width: 1.22%;
  top: -0.54%;
  right: 99.39%;
  bottom: 87.29%;
  left: -0.61%;
  border-right: 1.5px solid var(--cor-xuliz);
  box-sizing: border-box;
}
.sentTracedIcon {
  position: absolute;
  height: 102.91%;
  width: 15.35%;
  top: -1.45%;
  right: 84.9%;
  bottom: -1.45%;
  left: -0.25%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.produtos {
  position: absolute;
  top: 5.82%;
  left: 29.7%;
  line-height: 15px;
  font-weight: 600;
}
.sentTracedParent {
  position: absolute;
  height: 12.3%;
  width: 81.88%;
  top: 66.22%;
  right: 5.67%;
  bottom: 21.48%;
  left: 12.45%;
  cursor: pointer;
}
.vectorIcon40 {
  position: absolute;
  height: 109%;
  width: 21.43%;
  top: -4.5%;
  right: 79.55%;
  bottom: -4.5%;
  left: -0.97%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.home {
  position: absolute;
  top: 6%;
  left: 38.96%;
  line-height: 15px;
  font-weight: 600;
}
.vectorParent1 {
  position: absolute;
  height: 11.93%;
  width: 62.42%;
  top: 0;
  right: 25.13%;
  bottom: 88.07%;
  left: 12.45%;
  color: var(--branco);
}
.colorPaletteTraced {
  position: absolute;
  height: 102.08%;
  width: 14.35%;
  top: -1.65%;
  right: 85.88%;
  bottom: -0.43%;
  left: -0.23%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.relatrios {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 15px;
  font-weight: 600;
}
.relatriosWrapper {
  position: absolute;
  height: 98.79%;
  width: 72.22%;
  top: 1.21%;
  right: 0;
  bottom: 0;
  left: 27.78%;
}
.colorPaletteTracedParent {
  position: absolute;
  height: 10.87%;
  width: 87.55%;
  top: 44.6%;
  right: 0;
  bottom: 44.52%;
  left: 12.45%;
  cursor: pointer;
}
.vectorIcon41 {
  position: absolute;
  height: 95.96%;
  width: 21.43%;
  top: 4.04%;
  right: 78.57%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.xloja {
  position: absolute;
  top: 0;
  left: 41.89%;
  line-height: 15px;
  font-weight: 600;
}
.vectorParent2 {
  position: absolute;
  height: 11.19%;
  width: 59.99%;
  top: 22.67%;
  right: 28.37%;
  bottom: 66.14%;
  left: 11.64%;
  cursor: pointer;
}
.financeTracedIcon {
  position: absolute;
  height: 100%;
  width: 18.97%;
  top: 0;
  right: 81.03%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.saque {
  position: absolute;
  top: 0;
  left: 37.97%;
  line-height: 15px;
  font-weight: 600;
}
.financeTracedParent {
  position: absolute;
  height: 10.74%;
  width: 64.04%;
  top: 89.26%;
  right: 23.51%;
  bottom: 0;
  left: 12.45%;
  cursor: pointer;
}
.lineGroup {
  position: absolute;
  height: 12.93%;
  width: 6.42%;
  top: 16.48%;
  right: 90.35%;
  bottom: 70.59%;
  left: 3.23%;
  font-size: var(--ttulos-cabealho-size);
  color: var(--preto-c);
}
.logomarcaXulizIcon {
  position: absolute;
  top: 80px;
  left: calc(50% - 898px);
  width: 51.18px;
  height: 28px;
  overflow: hidden;
  object-fit: cover;
}
.parceirosIcon {
  position: absolute;
  top: 85px;
  left: 133.65px;
  width: 99.98px;
  height: 16.74px;
}
.telaHomeAreaparceirosChild6 {
  position: absolute;
  top: 85.25px;
  left: 122.9px;
  width: 1.5px;
  height: 17.34px;
}
.telaHomeAreaparceiros {
  position: relative;
  background-color: var(--branco);
  width: 100%;
  height: 1080px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-3xs);
  color: var(--cinza-padro);
  font-family: var(--ttulos-cabealho);
}
