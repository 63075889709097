.div,
.realizarVenda {
  position: absolute;
  top: 97px;
  left: 602px;
  line-height: 15px;
}
.div {
  left: 1662px;
  font-size: var(--font-size-xl);
  font-weight: 600;
}
.calendrio,
.histricoDeVendas,
.vendasDeHoje {
  position: absolute;
  top: 97px;
  left: 750px;
  line-height: 15px;
  color: var(--cinza-padro);
  cursor: pointer;
}
.calendrio,
.histricoDeVendas {
  left: 904px;
}
.calendrio {
  left: 1091px;
}
.telaXloja01AreaparceirosVazChild {
  position: absolute;
  height: 4.88%;
  width: 6.25%;
  top: 7.22%;
  right: 73.33%;
  bottom: 87.9%;
  left: 20.42%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.telaXloja01AreaparceirosVazItem {
  position: absolute;
  top: 77.5px;
  left: 556.5px;
  width: 1px;
  height: 54px;
}
.lineDiv,
.telaXloja01AreaparceirosVazInner {
  position: absolute;
  top: 326.5px;
  left: 1115.5px;
  border-top: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 699px;
  height: 1px;
}
.lineDiv {
  top: 566.58px;
}
.totalDaVenda {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 15px;
}
.wrapper {
  position: absolute;
  top: 7.24px;
  left: 49.41px;
  width: 72px;
  height: 15px;
}
.groupParent,
.rIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 39.41px;
  height: 30.08px;
}
.groupParent {
  top: 30px;
  width: 121.41px;
  font-size: var(--font-size-13xl);
  color: var(--preto-c);
}
.totalDaVendaParent {
  position: absolute;
  top: 372px;
  left: 1116px;
  width: 150px;
  height: 60.08px;
  font-size: var(--font-size-xl);
  color: var(--cinza-padro);
}
.totalAReceber {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 15px;
  font-weight: 600;
}
.antesDasTaxas {
  top: 20px;
  left: 0;
  font-size: var(--ttulos-cabealho-size);
  line-height: 15px;
}
.antesDasTaxas,
.div2,
.rIcon1 {
  position: absolute;
}
.div2 {
  top: 20.2px;
  left: 90.6px;
  line-height: 15px;
  font-weight: 600;
}
.rIcon1 {
  top: 0;
  left: 0;
  width: 70.6px;
  height: 56.4px;
}
.parent,
.totalAReceberParent {
  position: absolute;
  width: 225.6px;
}
.parent {
  top: 45px;
  left: 0;
  height: 56.4px;
  font-size: var(--font-size-41xl);
  color: var(--preto-c);
}
.totalAReceberParent {
  top: 612.08px;
  left: 1115px;
  height: 101.4px;
  font-size: var(--font-size-5xl);
}
.vectorIcon {
  position: relative;
  width: 19px;
  height: 19px;
  flex-shrink: 0;
}
.group,
.rParent {
  position: absolute;
}
.group {
  top: 6.6px;
  left: 49.41px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.rParent {
  top: 29.92px;
  left: 0;
  width: 147.41px;
  height: 30.08px;
  font-size: var(--font-size-13xl);
  color: var(--verde);
}
.cashbackParaOClienteParent {
  position: absolute;
  top: 462.08px;
  left: 1116px;
  width: 242px;
  height: 60px;
  font-size: var(--font-size-xl);
  color: var(--cinza-padro);
}
.enviarVenda {
  position: relative;
  line-height: 15px;
  font-weight: 600;
}
.vectorIcon1 {
  position: relative;
  width: 7px;
  height: 12px;
  flex-shrink: 0;
}
.default,
.groupChild {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  border-radius: var(--br-3xs);
}
.default {
  height: 23.72%;
  bottom: 76.28%;
  background-color: var(--preto-c);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-6xl) var(--padding-11xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-481xl);
}
.groupChild {
  height: 100%;
  bottom: 0;
  background-color: var(--branco-c);
}
.esperandoEnvioDa {
  position: absolute;
  top: 45.81%;
  left: 30.23%;
  line-height: 15px;
}
.rectangleParent {
  position: absolute;
  height: 65.33%;
  width: 99.86%;
  top: 34.67%;
  right: 0;
  bottom: 0;
  left: 0.14%;
  color: var(--cinza-padro);
}
.atualizaoVendaXloja {
  position: absolute;
  top: 758.48px;
  left: 1116px;
  width: 699px;
  height: 274px;
  font-size: var(--font-size-xl);
  color: var(--cor-xuliz);
}
.eMailCliente {
  position: absolute;
  top: 21px;
  left: 0;
  font-size: var(--font-size-base);
  line-height: 15px;
}
.nomeClienteParent {
  position: absolute;
  top: 22px;
  left: 95px;
  width: 166px;
  height: 36px;
}
.groupItem,
.userIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;
}
.userIcon {
  top: 23px;
  left: 23px;
  width: 34px;
  height: 34px;
  object-fit: cover;
}
.groupContainer {
  position: absolute;
  top: 202px;
  left: 1116px;
  width: 261px;
  height: 80px;
  font-size: var(--font-size-5xl);
}
.rectangleDiv {
  position: absolute;
  top: 187px;
  left: 392px;
  border-radius: var(--br-3xs);
  background-color: var(--branco-c);
  width: 650px;
  height: 855px;
}
.realizarVenda1 {
  position: absolute;
  top: 240px;
  left: 449px;
  font-size: var(--font-size-5xl);
  line-height: 15px;
  font-weight: 600;
}
.telaXloja01AreaparceirosVazChild1,
.telaXloja01AreaparceirosVazChild2,
.telaXloja01AreaparceirosVazChild3 {
  position: absolute;
  top: 314.5px;
  left: 448.5px;
  border-top: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 536px;
  height: 1px;
}
.telaXloja01AreaparceirosVazChild2,
.telaXloja01AreaparceirosVazChild3 {
  top: 579.5px;
}
.telaXloja01AreaparceirosVazChild3 {
  top: 844.5px;
}
.feitoComAmorFcamorWrapper {
  position: absolute;
  top: 25px;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--branco);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-mini) var(--padding-286xl) var(--padding-mini)
    var(--padding-xl);
  align-items: center;
  justify-content: flex-start;
  color: var(--cinza-padro);
}
.groupInner,
.nomeEmpresaestabelecimentoParent {
  position: absolute;
  top: 360px;
  left: 449px;
  width: 535px;
  height: 70px;
}
.groupInner {
  top: 25px;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--branco);
  height: 45px;
  overflow: hidden;
}
.nomeDoClienteParent {
  position: absolute;
  top: 730px;
  left: 449px;
  width: 535px;
  height: 70px;
}
.r {
  top: 29px;
  left: 20px;
  line-height: 15px;
  font-weight: 600;
}
.div4,
.r,
.rGroup {
  position: absolute;
}
.div4 {
  top: 29px;
  left: 63px;
  line-height: 15px;
  font-weight: 600;
}
.rGroup {
  top: 25px;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--branco);
  width: 535px;
  height: 73px;
  overflow: hidden;
  font-size: var(--font-size-5xl);
}
.frameDiv,
.valorASerCobradoParent {
  position: absolute;
  top: 890px;
  left: 449px;
  width: 535px;
  height: 98px;
}
.frameDiv {
  top: 25px;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--branco);
  width: 384px;
  height: 45px;
  overflow: hidden;
}
.cdigoDaVendaParent {
  position: absolute;
  top: 465px;
  left: 449px;
  width: 384px;
  height: 70px;
}
.chaveParent,
.groupChild1 {
  position: absolute;
  width: 339px;
}
.groupChild1 {
  top: 25px;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--branco);
  height: 45px;
  overflow: hidden;
}
.chaveParent {
  top: 625px;
  left: 645px;
  height: 70px;
}
.div5 {
  position: absolute;
  top: 40px;
  left: 95px;
  font-size: var(--font-size-xl);
  line-height: 15px;
  font-weight: 600;
}
.container {
  position: absolute;
  top: 25px;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--branco);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-mini) var(--padding-31xl) var(--padding-mini)
    var(--padding-xl);
  align-items: center;
  justify-content: flex-start;
  color: var(--cinza-padro);
}
.cashbackParent {
  position: absolute;
  top: 465px;
  left: 873px;
  width: 113px;
  height: 70px;
}
.cpf,
.vectorIcon2 {
  position: relative;
  flex-shrink: 0;
}
.cpf {
  line-height: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 86px;
  height: 15px;
}
.vectorIcon2 {
  width: 16px;
  height: 9px;
}
.defalut {
  position: absolute;
  top: 25px;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--branco);
  width: 156px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-mini) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-lgi);
}
.tipoDeChaveParent,
.vectorIcon3 {
  position: absolute;
  top: 625px;
  left: 449px;
  width: 156px;
  height: 70px;
}
.vectorIcon3 {
  top: -0.75px;
  left: -0.75px;
  width: 16.5px;
  height: 16.5px;
}
.centralDeAjuda {
  position: absolute;
  top: 0;
  left: 21px;
  line-height: 15px;
  font-weight: 600;
}
.vectorParent {
  position: absolute;
  top: 240px;
  left: 658px;
  width: 106px;
  height: 15px;
  cursor: pointer;
  font-size: var(--font-size-3xs);
}
.telaXloja01AreaparceirosVazChild4 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--preto-padro);
  width: 319px;
  height: 1080px;
}
.ellipseIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.feitoComAmor1,
.feitocomamorxulizcombr {
  position: absolute;
  left: 50px;
  line-height: 15px;
}
.feitoComAmor1 {
  top: 5px;
  font-weight: 600;
}
.feitocomamorxulizcombr {
  top: 20px;
  font-size: var(--font-size-3xs);
}
.ellipseParent {
  position: absolute;
  top: 999px;
  left: 62px;
  width: 192px;
  height: 40px;
  font-size: var(--font-size-xs);
  color: var(--branco);
}
.lineIcon,
.vectorGroup {
  position: absolute;
  top: 963.5px;
  left: 61.5px;
  width: 166px;
  height: 1px;
}
.vectorGroup {
  top: 894px;
  left: 62px;
  width: 96px;
  height: 15px;
  font-size: var(--font-size-3xs);
  color: var(--branco);
}
.closeTracedIcon {
  position: absolute;
  height: 100%;
  width: 36.52%;
  top: 0;
  right: 63.48%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.closeTracedParent {
  position: absolute;
  height: 1.39%;
  width: 2.14%;
  top: 85.56%;
  right: 94.64%;
  bottom: 13.06%;
  left: 3.23%;
  cursor: pointer;
  font-size: var(--font-size-3xs);
  color: var(--branco);
}
.vectorIcon5 {
  position: absolute;
  top: 1.25px;
  left: -0.75px;
  width: 16.5px;
  height: 13.77px;
}
.groupDiv,
.vectorContainer {
  position: absolute;
  top: 864px;
  left: 62px;
  width: 57px;
  height: 15px;
  font-size: var(--font-size-3xs);
  color: var(--branco);
}
.groupDiv {
  top: 834px;
  width: 106px;
}
.groupChild2 {
  position: absolute;
  height: 13.25%;
  width: 1.22%;
  top: 22.37%;
  right: 99.39%;
  bottom: 64.38%;
  left: -0.61%;
  border-right: 1.5px solid var(--cor-xuliz);
  box-sizing: border-box;
}
.sentTracedIcon {
  position: absolute;
  height: 102.91%;
  width: 15.35%;
  top: -1.45%;
  right: 84.9%;
  bottom: -1.45%;
  left: -0.25%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.produtos {
  position: absolute;
  top: 5.82%;
  left: 29.7%;
  line-height: 15px;
  font-weight: 600;
}
.sentTracedParent,
.vectorIcon7 {
  position: absolute;
  height: 12.3%;
  width: 81.88%;
  top: 66.22%;
  right: 5.67%;
  bottom: 21.48%;
  left: 12.45%;
}
.vectorIcon7 {
  height: 109%;
  width: 21.43%;
  top: -4.5%;
  right: 79.55%;
  bottom: -4.5%;
  left: -0.97%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.home {
  position: absolute;
  top: 6%;
  left: 38.96%;
  line-height: 15px;
  font-weight: 600;
}
.vectorParent1 {
  position: absolute;
  height: 11.93%;
  width: 62.42%;
  top: 0;
  right: 25.13%;
  bottom: 88.07%;
  left: 12.45%;
  cursor: pointer;
}
.colorPaletteTraced {
  position: absolute;
  height: 102.08%;
  width: 14.35%;
  top: -1.65%;
  right: 85.88%;
  bottom: -0.43%;
  left: -0.23%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.relatrios {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 15px;
  font-weight: 600;
}
.relatriosWrapper {
  position: absolute;
  height: 98.79%;
  width: 72.22%;
  top: 1.21%;
  right: 0;
  bottom: 0;
  left: 27.78%;
}
.colorPaletteTracedParent,
.vectorIcon8 {
  position: absolute;
  height: 10.87%;
  width: 87.55%;
  top: 44.6%;
  right: 0;
  bottom: 44.52%;
  left: 12.45%;
}
.vectorIcon8 {
  height: 95.96%;
  width: 21.43%;
  top: 4.04%;
  right: 78.57%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.xloja {
  position: absolute;
  top: 0;
  left: 41.89%;
  line-height: 15px;
  font-weight: 600;
}
.vectorParent2 {
  position: absolute;
  height: 11.19%;
  width: 59.99%;
  top: 22.67%;
  right: 28.37%;
  bottom: 66.14%;
  left: 11.64%;
  color: var(--branco);
}
.financeTracedIcon {
  position: absolute;
  height: 100%;
  width: 18.97%;
  top: 0;
  right: 81.03%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.saque {
  position: absolute;
  top: 0;
  left: 37.97%;
  line-height: 15px;
  font-weight: 600;
}
.financeTracedParent,
.lineParent {
  position: absolute;
  height: 10.74%;
  width: 64.04%;
  top: 89.26%;
  right: 23.51%;
  bottom: 0;
  left: 12.45%;
}
.lineParent {
  height: 12.93%;
  width: 6.42%;
  top: 16.57%;
  right: 90.35%;
  bottom: 70.49%;
  left: 3.23%;
  color: var(--preto-c);
}
.logomarcaXulizIcon {
  position: absolute;
  top: 81px;
  left: calc(50% - 898px);
  width: 51.18px;
  height: 28px;
  overflow: hidden;
  object-fit: cover;
}
.parceirosIcon {
  position: absolute;
  top: 86px;
  left: 133.65px;
  width: 99.98px;
  height: 16.74px;
}
.telaXloja01AreaparceirosVazChild5 {
  position: absolute;
  top: 86.25px;
  left: 122.9px;
  width: 1.5px;
  height: 17.34px;
}
.telaXloja01AreaparceirosVaz {
  position: relative;
  background-color: var(--branco);
  width: 100%;
  height: 1080px;
  overflow: hidden;
  text-align: left;
  font-size: var(--ttulos-cabealho-size);
  color: var(--preto-padro);
  font-family: var(--ttulos-cabealho);
}
