.loginAreaparceiros02Child {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--cor-xuliz);
  width: 1920px;
  height: 1080px;
}
.loginAreaparceiros02Item {
  position: absolute;
  top: 0;
  left: 469px;
  width: 982px;
  height: 1080px;
}
.logomarcaXulizIcon {
  position: absolute;
  top: calc(50% - 229.72px);
  left: calc(50% - 52px);
  width: 105px;
  height: 57.45px;
  overflow: hidden;
  object-fit: cover;
}
.groupChild,
.groupItem {
  position: absolute;
  top: 24.5px;
  left: -0.5px;
  background-color: var(--preto-padro);
  border-top: 1px solid var(--preto-padro);
  box-sizing: border-box;
  width: 351px;
  height: 1px;
}
.groupItem {
  top: 121.5px;
}
.eMailCnpjOu {
  top: 0;
  line-height: 20px;
}
.eMailCnpjOu,
.lineParent,
.senha {
  position: absolute;
  left: 0;
}
.senha {
  top: 97px;
  line-height: 20px;
}
.lineParent {
  top: 157.45px;
  width: 350px;
  height: 122px;
}
.entrar {
  position: absolute;
  top: 37.5%;
  left: 40.86%;
  line-height: 20px;
}
.botoEntrarChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-3xs);
  border: 1px solid var(--preto-padro);
  box-sizing: border-box;
}
.botoEntrar {
  position: absolute;
  top: 379.45px;
  left: 0;
  width: 350px;
  height: 80px;
}
.loginAreaparceiros02Inner,
.logomarcaXulizParent {
  position: absolute;
  top: calc(50% - 229.72px);
  left: calc(50% - 175px);
  width: 350px;
  height: 459.45px;
}
.loginAreaparceiros02Inner {
  top: calc(50% - 230px);
}
.loginAreaparceiros02 {
  position: relative;
  background-color: var(--preto-padro);
  width: 100%;
  height: 1080px;
  overflow: hidden;
  text-align: left;
  font-size: var(--ttulos-cabealho-size);
  color: var(--preto-padro);
  font-family: var(--ttulos-cabealho);
}
