.div {
  position: relative;
  line-height: 15px;
  display: flex;
  align-items: center;
  width: 86px;
  height: 15px;
  flex-shrink: 0;
}
.defalut,
.defalut1,
.defalut2,
.defalut3 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--branco);
  width: 278px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-mini) var(--padding-31xl) var(--padding-mini)
    var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}
.defalut1,
.defalut2,
.defalut3 {
  top: 45px;
}
.defalut2,
.defalut3 {
  top: 90px;
}
.defalut3 {
  top: 135px;
}
.div4 {
  position: relative;
  line-height: 15px;
}
.defalut4 {
  position: absolute;
  top: 180px;
  left: 0;
  background-color: var(--branco);
  width: 278px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-mini) var(--padding-31xl) var(--padding-mini)
    var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}
.listaFormaspagamento {
  position: relative;
  border-radius: 0 0 var(--br-3xs) var(--br-3xs);
  border: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 100%;
  height: 225px;
  overflow: hidden;
  text-align: left;
  font-size: var(--ttulos-cabealho-size);
  color: var(--preto-padro);
  font-family: var(--ttulos-cabealho);
}
