.container {
    display: flex;
    width: 100%;
    margin: 40px;
    flex-direction: column;
}

@media (max-width: 1570px) {
    .container {
      margin: 0px; 
    }
  }
  