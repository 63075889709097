.pesquisar,
.vectorIcon {
  position: relative;
  flex-shrink: 0;
}
.pesquisar {
  line-height: 20.72px;
  display: flex;
  align-items: center;
  width: 70px;
  height: 17px;
}
.vectorIcon {
  width: 19.01px;
  height: 19.01px;
}
.input {
  position: relative;
  border-radius: var(--br-3xs);
  background-color: var(--preto-c);
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 8px var(--padding-mini);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 380px;
  text-align: left;
  font-size: 14px;
  color: var(--cor-xuliz);
  font-family: var(--ttulos-cabealho);
}
