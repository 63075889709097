.div,
.realizarVenda {
  position: absolute;
  top: 97px;
  line-height: 15px;
}
.realizarVenda {
  left: 602px;
  color: var(--cinza-padro);
  cursor: pointer;
}
.div {
  left: 1662px;
  font-size: var(--font-size-xl);
  font-weight: 600;
}
.vendasDeHoje {
  left: 750px;
  color: var(--cinza-padro);
  cursor: pointer;
}
.calendrio,
.histricoDeVendas,
.vendasDeHoje {
  position: absolute;
  top: 97px;
  line-height: 15px;
}
.histricoDeVendas {
  left: 904px;
  color: var(--cinza-padro);
  cursor: pointer;
}
.calendrio {
  left: 1091px;
}
.telaXloja04AreaparceirosChild {
  position: absolute;
  height: 4.88%;
  width: 6.25%;
  top: 7.22%;
  right: 73.33%;
  bottom: 87.9%;
  left: 20.42%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.telaXloja04AreaparceirosItem {
  position: absolute;
  top: 77.5px;
  left: 556.5px;
  width: 1px;
  height: 54px;
}
.calendrioDeVendas {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 267px;
  height: 15.57px;
}
.selecioneNoCalendrio {
  margin: 0;
}
.selecioneNoCalendrioContainer1 {
  line-break: anywhere;
  width: 100%;
}
.selecioneNoCalendrioContainer {
  position: absolute;
  top: 31.13px;
  left: 0;
  font-size: var(--ttulos-cabealho-size);
  line-height: 15px;
  color: var(--cinza-padro);
  display: flex;
  align-items: center;
  width: 537px;
  height: 31.13px;
}
.calendrioDeVendasParent {
  position: absolute;
  top: 177px;
  left: 392px;
  width: 537px;
  height: 62.26px;
  font-size: var(--font-size-5xl);
}
.detalhamentoDoCalendrio {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 15px;
  font-weight: 600;
}
.detalhamentoDoCalendrioWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 355px;
  height: 15px;
}
.data01042023 {
  position: relative;
  line-height: 20.72px;
  background: linear-gradient(180deg, #494949, #3d3d3d 99.98%, #3d3d3d 99.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.data0104202308042023Wrapper {
  position: absolute;
  top: 30px;
  left: 0;
  border-radius: var(--br-8xs);
  background-color: var(--cor-xuliz);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-8xs);
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-3xs);
}
.groupParent {
  position: absolute;
  top: 192px;
  left: 1272px;
  width: 355px;
  height: 47px;
  font-size: var(--font-size-5xl);
}
.detailCalenderChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--branco-c);
  width: 568px;
  height: 763px;
}
.vectorIcon {
  position: absolute;
  top: 592px;
  left: 514px;
  width: 9px;
  height: 9px;
}
.formasDePagamento,
.vendasNoTotal {
  position: absolute;
  left: 43px;
  font-size: var(--font-size-xl);
  line-height: 14px;
  font-weight: 600;
}
.vendasNoTotal {
  top: 43px;
  color: var(--cinza-padro);
}
.formasDePagamento {
  top: 167px;
}
.crdito,
.r536565 {
  position: absolute;
  top: 16px;
  left: 0;
  line-height: 14px;
  display: flex;
  align-items: center;
  width: 56px;
  height: 14px;
}
.r536565 {
  left: 320px;
  text-align: right;
  width: 162px;
}
.formaspagamento,
.formaspagamento1,
.formaspagamento2 {
  position: absolute;
  top: 224px;
  left: 43px;
  border-bottom: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 482px;
  height: 45px;
}
.formaspagamento1,
.formaspagamento2 {
  top: 269px;
}
.formaspagamento2 {
  top: 314px;
}
.saldoXuliz {
  position: absolute;
  top: 16px;
  left: 0;
  line-height: 14px;
}
.formaspagamento3,
.formaspagamento4 {
  position: absolute;
  top: 359px;
  left: 43px;
  border-bottom: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 482px;
  height: 45px;
}
.formaspagamento4 {
  top: 404px;
}
.taxatarifaPaga {
  top: 559px;
  left: 247px;
}
.cashback,
.taxatarifaPaga,
.totalLquido {
  position: absolute;
  line-height: 14px;
  color: var(--cinza-padro);
  text-align: right;
  display: flex;
  align-items: center;
  width: 162px;
  height: 14px;
}
.totalLquido {
  top: 690px;
  left: 203px;
  font-size: var(--font-size-xl);
  font-weight: 600;
}
.cashback {
  top: 588px;
  left: 247px;
}
.r159765,
.r42605 {
  font-weight: 600;
  text-align: right;
}
.r42605 {
  position: absolute;
  top: 559px;
  left: 444px;
  line-height: 14px;
  color: var(--vermelho);
}
.r159765 {
  top: 588px;
  left: 432px;
  color: var(--verde);
}
.r1129010,
.r1331380,
.r159765,
.vendas {
  position: absolute;
  line-height: 14px;
}
.vendas {
  top: 67px;
  left: 43px;
  color: var(--cinza-padro);
}
.r1129010,
.r1331380 {
  top: 43px;
  left: 409px;
  font-size: var(--font-size-xl);
  text-align: right;
}
.r1129010 {
  top: 690px;
  left: 413px;
}
.detailCalenderItem {
  position: absolute;
  top: 121.5px;
  left: -2.5px;
  border-top: 5px solid var(--preto-c);
  box-sizing: border-box;
  width: 573px;
  height: 5px;
}
.detailCalender {
  position: absolute;
  height: 100%;
  width: 39.78%;
  top: 0;
  right: -1.47%;
  bottom: 0;
  left: 61.69%;
}
.calenderChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs) var(--br-3xs) 0 0;
  background-color: var(--preto-c);
  border: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 806px;
  height: 45px;
}
.timeday1w4i,
.timeday1w4i1,
.timeday1w4i2,
.timeday1w4i3,
.timeday1w4i4,
.timeday1w4i5,
.timeday1w4i6,
.timeday1w4i7,
.timeday1w4i8 {
  position: absolute;
  top: 45px;
  left: 0;
  border-right: 1px solid var(--cinza-padro);
  border-bottom: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 116px;
  height: 120px;
}
.timeday1w4i1,
.timeday1w4i2,
.timeday1w4i3,
.timeday1w4i4,
.timeday1w4i5,
.timeday1w4i6,
.timeday1w4i7,
.timeday1w4i8 {
  top: 643px;
  left: 230px;
}
.timeday1w4i2,
.timeday1w4i3,
.timeday1w4i4,
.timeday1w4i5,
.timeday1w4i6,
.timeday1w4i7,
.timeday1w4i8 {
  left: 345px;
}
.timeday1w4i3,
.timeday1w4i4,
.timeday1w4i5,
.timeday1w4i6,
.timeday1w4i7,
.timeday1w4i8 {
  left: 460px;
}
.timeday1w4i4,
.timeday1w4i5,
.timeday1w4i6,
.timeday1w4i7,
.timeday1w4i8 {
  left: 575px;
}
.timeday1w4i5,
.timeday1w4i6,
.timeday1w4i7,
.timeday1w4i8 {
  top: 45px;
  left: 115px;
}
.timeday1w4i6,
.timeday1w4i7,
.timeday1w4i8 {
  left: 230px;
}
.timeday1w4i7,
.timeday1w4i8 {
  left: 345px;
}
.timeday1w4i8 {
  left: 460px;
}
.div1 {
  position: absolute;
  top: 19px;
  left: 12px;
  line-height: 14px;
  color: var(--cinza-padro);
}
.div1,
.span {
  font-weight: 500;
}
.span1 {
  font-weight: 600;
}
.r1004442,
.vendas1 {
  position: absolute;
  top: 74px;
  left: 12px;
  line-height: 14px;
}
.vendas1 {
  top: 91px;
  font-size: var(--font-size-2xs);
}
.dayCalender,
.dayCalender1,
.dayCalender10,
.dayCalender2,
.dayCalender3,
.dayCalender4,
.dayCalender5,
.dayCalender6,
.dayCalender7,
.dayCalender8,
.dayCalender9 {
  position: absolute;
  top: 0;
  left: 115px;
  border-right: 1px solid var(--cinza-padro);
  border-bottom: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 116px;
  height: 120px;
}
.dayCalender1,
.dayCalender10,
.dayCalender2,
.dayCalender3,
.dayCalender4,
.dayCalender5,
.dayCalender6,
.dayCalender7,
.dayCalender8,
.dayCalender9 {
  top: 120px;
  height: 119px;
}
.dayCalender10,
.dayCalender2,
.dayCalender3,
.dayCalender4,
.dayCalender5,
.dayCalender6,
.dayCalender7,
.dayCalender8,
.dayCalender9 {
  left: 0;
}
.dayCalender10,
.dayCalender3,
.dayCalender4,
.dayCalender5,
.dayCalender6,
.dayCalender7,
.dayCalender8,
.dayCalender9 {
  left: -115px;
}
.dayCalender10,
.dayCalender4,
.dayCalender5,
.dayCalender6,
.dayCalender7,
.dayCalender8,
.dayCalender9 {
  left: -230px;
}
.dayCalender10,
.dayCalender5,
.dayCalender6,
.dayCalender7,
.dayCalender8,
.dayCalender9 {
  left: -345px;
}
.dayCalender10,
.dayCalender6,
.dayCalender7,
.dayCalender8,
.dayCalender9 {
  left: -460px;
}
.dayCalender10,
.dayCalender7,
.dayCalender8,
.dayCalender9 {
  left: -575px;
}
.dayCalender10,
.dayCalender8,
.dayCalender9 {
  top: 239px;
  left: 115px;
  height: 120px;
}
.dayCalender10,
.dayCalender9 {
  top: 359px;
}
.dayCalender10 {
  top: 479px;
  height: 119px;
}
.dayCalender11,
.dayCalender12,
.dayCalender13,
.dayCalender14,
.dayCalender15,
.dayCalender16,
.dayCalender17,
.dayCalender18,
.dayCalender19,
.dayCalender20,
.dayCalender21 {
  position: absolute;
  top: 239px;
  left: 0;
  border-right: 1px solid var(--cinza-padro);
  border-bottom: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 116px;
  height: 120px;
}
.dayCalender12,
.dayCalender13,
.dayCalender14,
.dayCalender15,
.dayCalender16,
.dayCalender17,
.dayCalender18,
.dayCalender19,
.dayCalender20,
.dayCalender21 {
  top: 359px;
}
.dayCalender13,
.dayCalender14,
.dayCalender15,
.dayCalender16,
.dayCalender17,
.dayCalender18,
.dayCalender19,
.dayCalender20,
.dayCalender21 {
  top: 479px;
  height: 119px;
}
.dayCalender14,
.dayCalender15,
.dayCalender16,
.dayCalender17,
.dayCalender18,
.dayCalender19,
.dayCalender20,
.dayCalender21 {
  top: 239px;
  left: -115px;
  height: 120px;
}
.dayCalender15,
.dayCalender16,
.dayCalender17,
.dayCalender18,
.dayCalender19,
.dayCalender20,
.dayCalender21 {
  top: 359px;
}
.dayCalender16,
.dayCalender17,
.dayCalender18,
.dayCalender19,
.dayCalender20,
.dayCalender21 {
  top: 479px;
  height: 119px;
}
.dayCalender17,
.dayCalender18,
.dayCalender19,
.dayCalender20,
.dayCalender21 {
  top: 239px;
  left: -230px;
  height: 120px;
}
.dayCalender18,
.dayCalender19,
.dayCalender20,
.dayCalender21 {
  top: 359px;
}
.dayCalender19,
.dayCalender20,
.dayCalender21 {
  top: 479px;
  height: 119px;
}
.dayCalender20,
.dayCalender21 {
  top: 239px;
  left: -345px;
  height: 120px;
}
.dayCalender21 {
  top: 359px;
}
.dayCalender22,
.dayCalender23,
.dayCalender24,
.dayCalender25,
.dayCalender26,
.dayCalender27,
.dayCalender28,
.dayCalender29,
.dayCalender30,
.timeday1w4i9 {
  position: absolute;
  top: 479px;
  left: -345px;
  border-right: 1px solid var(--cinza-padro);
  border-bottom: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 116px;
  height: 119px;
}
.dayCalender23,
.dayCalender24,
.dayCalender25,
.dayCalender26,
.dayCalender27,
.dayCalender28,
.dayCalender29,
.dayCalender30,
.timeday1w4i9 {
  top: 239px;
  left: -460px;
  height: 120px;
}
.dayCalender24,
.dayCalender25,
.dayCalender26,
.dayCalender27,
.dayCalender28,
.dayCalender29,
.dayCalender30,
.timeday1w4i9 {
  top: 359px;
}
.dayCalender25,
.dayCalender26,
.dayCalender27,
.dayCalender28,
.dayCalender29,
.dayCalender30,
.timeday1w4i9 {
  top: 479px;
  height: 119px;
}
.dayCalender26,
.dayCalender27,
.dayCalender28,
.dayCalender29,
.dayCalender30,
.timeday1w4i9 {
  top: 598px;
  height: 120px;
}
.dayCalender27,
.dayCalender28,
.dayCalender29,
.dayCalender30,
.timeday1w4i9 {
  top: 239px;
  left: -575px;
}
.dayCalender28,
.dayCalender29,
.dayCalender30,
.timeday1w4i9 {
  top: 359px;
}
.dayCalender29,
.dayCalender30,
.timeday1w4i9 {
  top: 479px;
  height: 119px;
}
.dayCalender30,
.timeday1w4i9 {
  top: 598px;
  height: 120px;
}
.timeday1w4i9 {
  top: 45px;
  left: 575px;
  text-align: left;
  font-size: var(--font-size-smi);
  color: var(--preto-c);
}
.dom,
.seg {
  top: 15px;
  line-height: 14px;
  font-weight: 600;
}
.dom {
  position: absolute;
  left: 39px;
}
.seg {
  left: 158px;
}
.qua,
.seg,
.ter {
  position: absolute;
}
.ter {
  top: 15px;
  left: 276px;
  line-height: 14px;
  font-weight: 600;
}
.qua {
  left: 387px;
}
.qua,
.qui,
.sex {
  top: 15px;
  line-height: 14px;
  font-weight: 600;
}
.qui {
  position: absolute;
  left: 504px;
}
.sex {
  left: 619px;
}
.calender,
.sb,
.sex {
  position: absolute;
}
.sb {
  top: 15px;
  left: 734px;
  line-height: 14px;
  font-weight: 600;
}
.calender {
  height: 100%;
  width: 56.44%;
  top: 0;
  right: 43.56%;
  bottom: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--branco);
  border: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
  color: var(--cor-xuliz);
}
.component3 {
  position: absolute;
  top: 279px;
  left: 392px;
  width: 1428px;
  height: 763px;
}
.telaXloja04AreaparceirosInner {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--preto-padro);
  width: 319px;
  height: 1080px;
}
.groupChild {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.feitoComAmor,
.feitocomamorxulizcombr {
  position: absolute;
  left: 50px;
  line-height: 15px;
}
.feitoComAmor {
  top: 5px;
  font-weight: 600;
}
.feitocomamorxulizcombr {
  top: 20px;
  font-size: var(--font-size-3xs);
}
.ellipseParent {
  position: absolute;
  top: 999px;
  left: 62px;
  width: 192px;
  height: 40px;
  font-size: var(--font-size-xs);
  color: var(--branco);
}
.lineIcon,
.vectorIcon1 {
  position: absolute;
  top: 963.5px;
  left: 61.5px;
  width: 166px;
  height: 1px;
}
.vectorIcon1 {
  top: -0.75px;
  left: -0.75px;
  width: 16.5px;
  height: 16.5px;
}
.configuraes {
  position: absolute;
  top: 0;
  left: 21px;
  line-height: 15px;
  font-weight: 600;
}
.vectorParent {
  position: absolute;
  top: 894px;
  left: 62px;
  width: 96px;
  height: 15px;
  font-size: var(--font-size-3xs);
  color: var(--branco);
}
.closeTracedIcon {
  position: absolute;
  height: 100%;
  width: 36.52%;
  top: 0;
  right: 63.48%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.closeTracedParent {
  position: absolute;
  height: 1.39%;
  width: 2.14%;
  top: 85.56%;
  right: 94.64%;
  bottom: 13.06%;
  left: 3.23%;
  cursor: pointer;
  font-size: var(--font-size-3xs);
  color: var(--branco);
}
.vectorIcon2 {
  position: absolute;
  top: 1.25px;
  left: -0.75px;
  width: 16.5px;
  height: 13.77px;
}
.vectorContainer,
.vectorGroup {
  position: absolute;
  top: 864px;
  left: 62px;
  width: 57px;
  height: 15px;
  font-size: var(--font-size-3xs);
  color: var(--branco);
}
.vectorContainer {
  top: 834px;
  width: 106px;
}
.groupItem {
  position: absolute;
  height: 13.25%;
  width: 1.22%;
  top: 22.37%;
  right: 99.39%;
  bottom: 64.38%;
  left: -0.61%;
  border-right: 1.5px solid var(--cor-xuliz);
  box-sizing: border-box;
}
.sentTracedIcon {
  position: absolute;
  height: 102.91%;
  width: 15.35%;
  top: -1.45%;
  right: 84.9%;
  bottom: -1.46%;
  left: -0.25%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.produtos {
  position: absolute;
  top: 5.82%;
  left: 29.7%;
  line-height: 15px;
  font-weight: 600;
}
.sentTracedParent {
  position: absolute;
  height: 12.3%;
  width: 81.88%;
  top: 66.22%;
  right: 5.67%;
  bottom: 21.48%;
  left: 12.45%;
}
.vectorIcon4 {
  height: 109%;
  width: 21.43%;
  top: -4.5%;
  right: 79.55%;
  bottom: -4.5%;
  left: -0.97%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.groupDiv,
.home,
.vectorIcon4 {
  position: absolute;
}
.home {
  top: 6%;
  left: 38.96%;
  line-height: 15px;
  font-weight: 600;
}
.groupDiv {
  height: 11.93%;
  width: 62.42%;
  top: 0;
  right: 25.13%;
  bottom: 88.07%;
  left: 12.45%;
  cursor: pointer;
}
.colorPaletteTraced {
  position: absolute;
  height: 102.08%;
  width: 14.35%;
  top: -1.65%;
  right: 85.88%;
  bottom: -0.43%;
  left: -0.23%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.relatrios {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 15px;
  font-weight: 600;
}
.relatriosWrapper {
  position: absolute;
  height: 98.79%;
  width: 72.22%;
  top: 1.21%;
  right: 0;
  bottom: 0;
  left: 27.78%;
}
.colorPaletteTracedParent,
.vectorIcon5 {
  position: absolute;
  height: 10.87%;
  width: 87.55%;
  top: 44.6%;
  right: 0;
  bottom: 44.52%;
  left: 12.45%;
}
.vectorIcon5 {
  height: 95.96%;
  width: 21.43%;
  top: 4.04%;
  right: 78.57%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.xloja {
  position: absolute;
  top: 0;
  left: 41.89%;
  line-height: 15px;
  font-weight: 600;
}
.vectorParent1 {
  position: absolute;
  height: 11.19%;
  width: 59.99%;
  top: 22.67%;
  right: 28.37%;
  bottom: 66.14%;
  left: 11.64%;
  color: var(--branco);
}
.financeTracedIcon {
  position: absolute;
  height: 100%;
  width: 18.97%;
  top: 0;
  right: 81.03%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.saque {
  position: absolute;
  top: 0;
  left: 37.97%;
  line-height: 15px;
  font-weight: 600;
}
.financeTracedParent,
.lineParent {
  position: absolute;
  height: 10.74%;
  width: 64.04%;
  top: 89.26%;
  right: 23.51%;
  bottom: 0;
  left: 12.45%;
}
.lineParent {
  height: 12.93%;
  width: 6.42%;
  top: 16.57%;
  right: 90.35%;
  bottom: 70.49%;
  left: 3.23%;
  color: var(--preto-c);
}
.logomarcaXulizIcon {
  position: absolute;
  top: 81px;
  left: calc(50% - 898px);
  width: 51.18px;
  height: 28px;
  overflow: hidden;
  object-fit: cover;
}
.parceirosIcon {
  position: absolute;
  top: 86px;
  left: 133.65px;
  width: 99.98px;
  height: 16.74px;
}
.telaXloja04AreaparceirosChild1 {
  position: absolute;
  top: 86.25px;
  left: 122.9px;
  width: 1.5px;
  height: 17.34px;
}
.telaXloja04Areaparceiros {
  position: relative;
  background-color: var(--branco);
  width: 100%;
  height: 1080px;
  overflow: hidden;
  text-align: left;
  font-size: var(--ttulos-cabealho-size);
  color: var(--preto-padro);
  font-family: var(--ttulos-cabealho);
}
