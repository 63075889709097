.custom-input {
    border: none;
    background: #3D3D3D;
    color: #F2E2C4;
    outline: none;
  }

.custom-input::placeholder {
    color: #F2E2C4;
}

@media (max-width: 1024px) {

  .contestacao{
    transform: scale(0.8);
    transform-origin: left;
  }

  .container-valores{
    width: 1140px;
    transform: scale(0.63);
    transform-origin: -20px;
  }

  .container-lancamentos{
    width: 1120px;
    transform: scale(0.63);
    transform-origin: left;
    margin-top: -160px;
  }
}

@media (max-width: 768px) {
  .container-principal-mobile{
    display: block;
    margin: 0;
  }

  .container-valores{
    transform: scale(0.8);
  }

  .display-p{
    transform: scale(1.4);
    transform-origin: left;
  }

  .display-contestacao{
    transform: scale(0.9);
    transform-origin: 2500px;
  }
}

@media (max-width: 425px) {
  .container-principal-mobile{
    transform: scale(1.1);
    margin-top: 20px;
  }

  .container-valores{
    transform: scale(0.53);
    transform-origin: left;
    height: 150px;
    margin-top: -40px;
    margin-bottom: 20px;
  }

  .container-lancamentos{
    width: 570px;
    transform: scale(0.63);
    transform-origin: left;
    margin-top: -160px;
  }

  .display-p{
    transform: scale(1.0);
    transform-origin: left;
  }

  .display-contestacao{
    transform: scale(0.8);
    transform-origin: -100px;
  }
}

@media (max-width: 375px) {
  .container-valores{
    transform: scale(0.46);
    height: 150px;
    margin-top: -40px;
    margin-bottom: 10px;
  }

  .container-lancamentos{
    width: 490px;
    transform: scale(0.63);
    transform-origin: left;
    margin-top: -160px;
  }

  .display-p{
    transform: scale(0.9);
    transform-origin: left;
  }

  .display-contestacao{
    transform: scale(0.8);
    transform-origin: -255px;
  }
}

@media (max-width: 320px) {
  .container-valores{
    transform: scale(0.38);
    transform-origin: left;
    height: 150px;
    margin-top: -40px;
    
  }

  .container-lancamentos{
    width: 405px;
    transform: scale(0.63);
    transform-origin: left;
    margin-top: -160px;
  }

  .display-p{
    transform: scale(0.8);
    transform-origin: left;
  }

  .display-contestacao{
    transform: scale(0.6);
    transform-origin: -125px;
  }
}