.div,
.realizarVenda {
  position: absolute;
  top: 97px;
  line-height: 15px;
}
.realizarVenda {
  left: 602px;
  color: var(--cinza-padro);
  cursor: pointer;
}
.div {
  left: 1662px;
  font-size: var(--font-size-xl);
  font-weight: 600;
  color: var(--preto-padro);
}
.calendrio,
.histricoDeVendas,
.vendasDeHoje {
  position: absolute;
  top: 97px;
  line-height: 15px;
  color: var(--cinza-padro);
}
.vendasDeHoje {
  left: 750px;
  cursor: pointer;
}
.calendrio,
.histricoDeVendas {
  left: 904px;
}
.calendrio {
  left: 1091px;
  cursor: pointer;
}
.telaXlojaDetalhevendaAreapaChild {
  position: absolute;
  height: 4.88%;
  width: 6.25%;
  top: 7.22%;
  right: 73.33%;
  bottom: 87.9%;
  left: 20.42%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.telaXlojaDetalhevendaAreapaItem {
  position: absolute;
  top: 77.5px;
  left: 556.5px;
  width: 1px;
  height: 54px;
}
.detalheDaVenda,
.detalheDaVendaWrapper {
  position: absolute;
  width: 267px;
  height: 15.57px;
}
.detalheDaVenda {
  top: 0;
  left: 0;
  line-height: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.detalheDaVendaWrapper {
  top: 193px;
  left: 392px;
  font-size: var(--font-size-5xl);
  color: var(--preto-padro);
}
.histricoDeVendas1 {
  position: relative;
  line-height: 20.72px;
  font-weight: 600;
  background: linear-gradient(180deg, #494949, #3d3d3d 99.98%, #3d3d3d 99.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.vectorIcon {
  position: relative;
  width: 3.64px;
  height: 6.5px;
  flex-shrink: 0;
}
.detalheDaVenda1 {
  position: relative;
  line-height: 20.72px;
  background: linear-gradient(180deg, #494949, #3d3d3d 99.98%, #3d3d3d 99.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.histricoDeVendasParent {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-8xs);
  background-color: var(--cor-xuliz);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-8xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-8xs);
}
.telaXlojaDetalhevendaAreapaInner {
  position: absolute;
  top: 223.57px;
  left: 392px;
  width: 213.14px;
  height: 17px;
  font-size: var(--font-size-3xs);
}
.groupChild,
.rectangleDiv {
  position: absolute;
  top: 0;
  left: 0;
}
.rectangleDiv {
  background-color: var(--preto-padro);
  width: 319px;
  height: 1080px;
}
.groupChild {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.feitoComAmor,
.feitocomamorxulizcombr {
  position: absolute;
  left: 50px;
  line-height: 15px;
}
.feitoComAmor {
  top: 5px;
  font-weight: 600;
}
.feitocomamorxulizcombr {
  top: 20px;
  font-size: var(--font-size-3xs);
}
.ellipseParent {
  position: absolute;
  top: 999px;
  left: 62px;
  width: 192px;
  height: 40px;
  font-size: var(--font-size-xs);
}
.lineIcon,
.vectorIcon1 {
  position: absolute;
  top: 963.5px;
  left: 61.5px;
  width: 166px;
  height: 1px;
}
.vectorIcon1 {
  top: -0.75px;
  left: -0.75px;
  width: 16.5px;
  height: 16.5px;
}
.configuraes {
  position: absolute;
  top: 0;
  left: 21px;
  line-height: 15px;
  font-weight: 600;
}
.vectorParent {
  position: absolute;
  top: 894px;
  left: 62px;
  width: 96px;
  height: 15px;
  font-size: var(--font-size-3xs);
}
.closeTracedIcon {
  position: absolute;
  height: 100%;
  width: 36.52%;
  top: 0;
  right: 63.48%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.closeTracedParent {
  position: absolute;
  height: 1.39%;
  width: 2.14%;
  top: 85.56%;
  right: 94.64%;
  bottom: 13.06%;
  left: 3.23%;
  cursor: pointer;
  font-size: var(--font-size-3xs);
}
.vectorIcon2 {
  position: absolute;
  top: 1.25px;
  left: -0.75px;
  width: 16.5px;
  height: 13.77px;
}
.vectorContainer,
.vectorGroup {
  position: absolute;
  top: 864px;
  left: 62px;
  width: 57px;
  height: 15px;
  font-size: var(--font-size-3xs);
}
.vectorContainer {
  top: 834px;
  width: 106px;
}
.groupItem {
  position: absolute;
  height: 13.25%;
  width: 1.22%;
  top: 22.37%;
  right: 99.39%;
  bottom: 64.38%;
  left: -0.61%;
  border-right: 1.5px solid var(--cor-xuliz);
  box-sizing: border-box;
}
.sentTracedIcon {
  
  color: red;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  padding-right: 10px;
}
.produtos {
  font-size: 15;
  line-height: 15px;
  font-weight: 600;
}
.sentTracedParent {
  position: absolute;
  background-color:hotpink;
  height: 50;
}
.vectorIcon4 {
  height: 109%;
  width: 21.43%;
  top: -4.5%;
  right: 79.55%;
  bottom: -4.5%;
  left: -0.97%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.groupDiv,
.home,
.vectorIcon4 {
  position: absolute;
}
.home {
  top: 6%;
  left: 38.96%;
  line-height: 15px;
  font-weight: 600;
}
.groupDiv {
  height: 11.93%;
  width: 62.42%;
  top: 0;
  right: 25.13%;
  bottom: 88.07%;
  left: 12.45%;
  cursor: pointer;
}
.colorPaletteTraced {
  position: absolute;
  height: 102.08%;
  width: 14.35%;
  top: -1.65%;
  right: 85.88%;
  bottom: -0.43%;
  left: -0.23%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.relatrios {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 15px;
  font-weight: 600;
}
.relatriosWrapper {
  position: absolute;
  height: 98.79%;
  width: 72.22%;
  top: 1.21%;
  right: 0;
  bottom: 0;
  left: 27.78%;
}
.colorPaletteTracedParent,
.vectorIcon5 {
  position: absolute;
  height: 10.87%;
  width: 87.55%;
  top: 44.6%;
  right: 0;
  bottom: 44.52%;
  left: 12.45%;
}
.vectorIcon5 {
  height: 95.96%;
  width: 21.43%;
  top: 4.04%;
  right: 78.57%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.xloja {
  position: absolute;
  top: 0;
  left: 41.89%;
  line-height: 15px;
  font-weight: 600;
}
.vectorParent1 {
  position: absolute;
  height: 11.19%;
  width: 59.99%;
  top: 22.67%;
  right: 28.37%;
  bottom: 66.14%;
  left: 11.64%;
  color: var(--branco);
}
.financeTracedIcon {
  position: absolute;
  height: 100%;
  width: 18.97%;
  top: 0;
  right: 81.03%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.saque {
  position: absolute;
  top: 0;
  left: 37.97%;
  line-height: 15px;
  font-weight: 600;
}
.financeTracedParent,
.lineParent {
  position: absolute;
  height: 10.74%;
  width: 64.04%;
  top: 89.26%;
  right: 23.51%;
  bottom: 0;
  left: 12.45%;
}
.lineParent {
  height: 12.93%;
  width: 6.42%;
  top: 16.57%;
  right: 90.35%;
  bottom: 70.49%;
  left: 3.23%;
  color: var(--preto-c);
}
.logomarcaXulizIcon {
  position: absolute;
  top: 81px;
  left: calc(50% - 898px);
  width: 51.18px;
  height: 28px;
  overflow: hidden;
  object-fit: cover;
}
.parceirosIcon {
  position: absolute;
  top: 86px;
  left: 133.65px;
  width: 99.98px;
  height: 16.74px;
}
.telaXlojaDetalhevendaAreapaChild1 {
  position: absolute;
  top: 86.25px;
  left: 122.9px;
  width: 1.5px;
  height: 17.34px;
}
.telaXlojaDetalhevendaAreapa {
  position: relative;
  background-color: var(--branco);
  width: 100%;
  height: 1080px;
  overflow: hidden;
  text-align: left;
  font-size: var(--ttulos-cabealho-size);
  color: var(--branco);
  font-family: var(--ttulos-cabealho);
}
