.div,
.realizarVenda {
  position: absolute;
  top: 97px;
  line-height: 15px;
}
.realizarVenda {
  left: 602px;
  cursor: pointer;
}
.div {
  left: 1662px;
  font-size: var(--font-size-xl);
  font-weight: 600;
  color: var(--preto-padro);
}
.vendasDeHoje {
  left: 750px;
  cursor: pointer;
}
.calendrio,
.histricoDeVendas,
.vendasDeHoje {
  position: absolute;
  top: 97px;
  line-height: 15px;
}
.histricoDeVendas {
  left: 904px;
  color: var(--preto-padro);
}
.calendrio {
  left: 1091px;
  cursor: pointer;
}
.telaXloja03AreaparceirosChild {
  position: absolute;
  height: 4.88%;
  width: 6.25%;
  top: 7.22%;
  right: 73.33%;
  bottom: 87.9%;
  left: 20.42%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.telaXloja03AreaparceirosItem {
  position: absolute;
  top: 77.5px;
  left: 556.5px;
  width: 1px;
  height: 54px;
}
.datahora {
  position: absolute;
  top: 42.13%;
  left: 20.42%;
  line-height: 20.72px;
}
.previsoDe {
  margin: 0;
}
.estabelecimento,
.previsoDePagamentoContainer {
  position: absolute;
  top: 41.2%;
  left: 29.9%;
  line-height: 20.72px;
}
.estabelecimento {
  top: 42.13%;
  left: 37.5%;
}
.bandeira,
.cashback,
.formaDePagamento,
.valorVenda {
  position: absolute;
  top: 42.13%;
  left: 48.33%;
  line-height: 20.72px;
}
.cashback,
.formaDePagamento,
.valorVenda {
  left: 53.44%;
}
.cashback,
.valorVenda {
  left: 63.59%;
}
.cashback {
  left: 69.74%;
}
.status,
.valorDaTaxatarifaContainer,
.valorLquido {
  position: absolute;
  top: 41.2%;
  left: 75.16%;
  line-height: 20.72px;
}
.status,
.valorLquido {
  top: 42.22%;
  left: 80.63%;
}
.status {
  top: 42.31%;
  left: 87.08%;
}
.telaXloja03AreaparceirosInner {
  position: absolute;
  top: 490.5px;
  left: 391.5px;
  border-top: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 1449px;
  height: 1px;
}
.rectangleDiv,
.telaXloja03AreaparceirosChild1 {
  position: absolute;
  top: 175.68px;
  left: 1562px;
  border-radius: var(--br-3xs);
  background-color: var(--branco);
  border: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 278px;
  height: 45px;
}
.telaXloja03AreaparceirosChild1 {
  left: 1270px;
}
.valorDaVenda,
.valorDaVenda1 {
  position: absolute;
  top: 190.68px;
  left: 1579px;
  line-height: 15px;
}
.valorDaVenda1 {
  left: 1287px;
}
.div1,
.quantidadeDeVendas {
  position: absolute;
  top: 1px;
  left: 0;
  line-height: 20.72px;
}
.div1 {
  top: 0;
  left: 318px;
  font-size: var(--font-size-5xl);
  font-weight: 600;
  color: var(--preto-padro);
}
.div2,
.r,
.valorBruto,
.valorLquido1 {
  position: absolute;
  top: 1px;
  left: 413px;
  line-height: 20.72px;
}
.div2,
.r,
.valorLquido1 {
  left: 819px;
}
.div2,
.r {
  top: 0;
  left: 0;
}
.div2 {
  left: 38px;
  font-weight: 600;
}
.rGroup,
.rParent {
  position: absolute;
  top: 0;
  left: 615px;
  width: 154px;
  height: 17px;
  font-size: var(--font-size-5xl);
  color: var(--preto-padro);
}
.rGroup {
  left: 1035px;
  width: 148px;
}
.groupChild,
.groupItem {
  position: absolute;
  top: 0.5px;
  left: 387.5px;
  width: 1px;
  height: 15px;
}
.groupItem {
  left: 793.5px;
}
.quantidadeDeVendasParent {
  position: relative;
  width: 1183px;
  height: 17px;
  flex-shrink: 0;
}
.frameDiv {
  position: absolute;
  top: calc(50% - 272px);
  left: calc(50% - 568.5px);
  background-color: var(--branco-c);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-mini) 133px;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-xl);
}
.filtrosSelecionados {
  position: absolute;
  top: 23.53%;
  left: 0;
  line-height: 20.72px;
  font-weight: 600;
}
.data01042023 {
  position: relative;
  line-height: 20.72px;
  background: linear-gradient(180deg, #494949, #3d3d3d 99.98%, #3d3d3d 99.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.vectorIcon {
  position: relative;
  width: 6.66px;
  height: 6.66px;
  flex-shrink: 0;
}
.data0104202304042023Parent {
  position: absolute;
  top: 0;
  left: 172px;
  border-radius: var(--br-8xs);
  background-color: var(--cor-xuliz);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-8xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
  font-size: var(--font-size-3xs);
}
.filtrosSelecionadosParent {
  position: absolute;
  height: 1.57%;
  width: 18.21%;
  top: 21.85%;
  right: 61.37%;
  bottom: 76.57%;
  left: 20.42%;
}
.exportar {
  position: relative;
  line-height: 15px;
  font-weight: 600;
}
.vectorIcon1 {
  position: relative;
  width: 14.5px;
  height: 14.5px;
  flex-shrink: 0;
}
.exportarParent {
  position: absolute;
  top: 363.18px;
  left: 1620px;
  border-radius: var(--br-3xs);
  background-color: var(--preto-padro);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-mini);
  align-items: flex-start;
  justify-content: flex-start;
  gap: 110px;
  color: var(--branco);
}
.vectorIcon2 {
  position: relative;
  width: 16.5px;
  height: 16.5px;
  flex-shrink: 0;
}
.vectorWrapper {
  position: absolute;
  top: 363.18px;
  left: 1560px;
  border-radius: var(--br-3xs);
  background-color: var(--cor-xuliz);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-mini);
  align-items: flex-start;
  justify-content: flex-start;
}
.crdito,
.div4 {
  position: relative;
  line-height: 20.72px;
}
.div4 {
  position: absolute;
  top: 0;
  left: 26px;
  font-weight: 600;
}
.rContainer {
  position: relative;
  width: 109px;
  height: 10px;
  flex-shrink: 0;
  color: var(--preto-padro);
}
.crditoParent {
  position: absolute;
  top: 350px;
  left: 392px;
  border-radius: var(--br-3xs);
  border: 1px solid var(--preto-padro);
  box-sizing: border-box;
  width: 301px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-6xl) var(--padding-mini);
  align-items: center;
  justify-content: center;
  gap: var(--gap-46xl);
}
.groupDiv {
  position: relative;
  width: 96px;
  height: 10px;
  flex-shrink: 0;
  color: var(--preto-padro);
}
.dbitoParent {
  position: absolute;
  top: 350px;
  left: 714px;
  border-radius: var(--br-3xs);
  border: 1px solid var(--preto-padro);
  box-sizing: border-box;
  width: 277px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-6xl) var(--padding-mini);
  align-items: center;
  justify-content: center;
  gap: var(--gap-46xl);
}
.rParent1 {
  position: relative;
  width: 94px;
  height: 10px;
  flex-shrink: 0;
  color: var(--preto-padro);
}
.pixParent {
  position: absolute;
  top: 350px;
  left: 1011px;
  border-radius: var(--br-3xs);
  border: 1px solid var(--preto-padro);
  box-sizing: border-box;
  width: 236px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-6xl) var(--padding-mini);
  align-items: center;
  justify-content: center;
  gap: var(--gap-46xl);
}
.rParent2 {
  position: relative;
  width: 99px;
  height: 10px;
  flex-shrink: 0;
  color: var(--preto-padro);
}
.groupInner,
.xulizParent {
  position: absolute;
  box-sizing: border-box;
}
.xulizParent {
  top: 350px;
  left: 1268px;
  border-radius: var(--br-3xs);
  border: 1px solid var(--preto-padro);
  width: 260px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-6xl) var(--padding-mini);
  align-items: center;
  justify-content: center;
  gap: var(--gap-46xl);
}
.groupInner {
  top: 53.5px;
  left: -0.5px;
  border-top: 1px solid var(--cinza-padro);
  width: 1449px;
  height: 1px;
}
.r162,
.r4590 {
  position: absolute;
  top: 11px;
  left: 1156px;
  line-height: 20.72px;
}
.r162 {
  left: 1051px;
  font-weight: 600;
  color: var(--vermelho);
}
.aprovado,
.div10,
.div8,
.div9,
.r5400,
.r648,
.xuliz1 {
  position: absolute;
  top: 11px;
  left: 947px;
  line-height: 20.72px;
}
.aprovado,
.div10,
.div8,
.div9,
.r5400,
.xuliz1 {
  left: 829px;
}
.aprovado,
.div10,
.div8,
.div9,
.xuliz1 {
  top: 10px;
  left: 634px;
}
.aprovado,
.div10,
.div8,
.div9 {
  left: 328px;
}
.aprovado,
.div10,
.div9 {
  left: 0;
}
.aprovado,
.div10 {
  left: 182px;
}
.aprovado {
  height: 66.67%;
  width: 80.21%;
  top: 13.33%;
  left: 19.79%;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.vectorIcon3 {
  position: absolute;
  height: 93.33%;
  width: 14.97%;
  top: 1.67%;
  right: 84.76%;
  bottom: 5%;
  left: 0.27%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.aprovada {
  position: absolute;
  top: 10px;
  left: 1280px;
  width: 93.5px;
  height: 15px;
  color: var(--verde);
}
.bandeirasChild,
.vectorIcon4 {
  position: absolute;
  top: 11px;
  left: 1438px;
  width: 3px;
  height: 13px;
}
.bandeirasChild {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-10xs);
  background-color: var(--preto-padro);
}
.bandeirasItem {
  position: absolute;
  height: 63.33%;
  width: 65.45%;
  top: 20%;
  right: 16.36%;
  bottom: 16.67%;
  left: 18.18%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.bandeiras,
.r4590Parent {
  position: absolute;
  top: 0;
  left: 536px;
  width: 55px;
  height: 30px;
}
.r4590Parent {
  left: 0;
  width: 1441px;
}
.lineContainer,
.lineGroup,
.lineParent,
.lineParent1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 1448px;
  height: 54px;
}
.lineContainer,
.lineGroup,
.lineParent1 {
  top: 78px;
}
.lineContainer,
.lineParent1 {
  top: 156px;
}
.lineParent1 {
  top: 234px;
}
.lineParent2,
.lineParent3,
.lineParent4,
.vectorIcon17 {
  position: absolute;
  top: 312px;
  left: 0;
  width: 1448px;
  height: 54px;
}
.lineParent3,
.lineParent4,
.vectorIcon17 {
  top: 390px;
}
.lineParent4,
.vectorIcon17 {
  top: 468px;
}
.vectorIcon17 {
  height: 83.33%;
  width: 13.37%;
  top: 6.67%;
  right: 85.56%;
  bottom: 10%;
  left: 1.07%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.lineParent5,
.lineParent6,
.lineParent7 {
  position: absolute;
  top: 546px;
  left: 0;
  width: 1448px;
  height: 54px;
}
.lineParent6,
.lineParent7 {
  top: 624px;
}
.lineParent7 {
  top: 702px;
}
.groupParent,
.lineParent10,
.lineParent8,
.lineParent9 {
  position: absolute;
  top: 780px;
  left: 0;
  width: 1448px;
  height: 54px;
}
.groupParent,
.lineParent10,
.lineParent9 {
  top: 858px;
}
.groupParent,
.lineParent10 {
  top: 936px;
}
.groupParent {
  top: 515px;
  left: 392px;
  height: 509px;
  overflow-y: auto;
  color: var(--preto-padro);
}
.perodo {
  position: relative;
  line-height: 15px;
}
.vectorIcon29 {
  position: relative;
  width: 15px;
  height: 16.5px;
  flex-shrink: 0;
}
.default {
  position: absolute;
  top: 176px;
  left: 392px;
  border-radius: var(--br-3xs);
  background-color: var(--branco);
  border: 1px solid var(--cinza-padro);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-mini) var(--padding-lg);
  align-items: center;
  justify-content: center;
  gap: 170px;
}
.vectorIcon30 {
  position: relative;
  width: 16px;
  height: 9px;
  flex-shrink: 0;
}
.bandeiras13 {
  position: absolute;
  top: 176px;
  left: 685px;
  border-radius: var(--br-3xs);
  background-color: var(--branco);
  border: 1px solid var(--cinza-padro);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-mini) var(--padding-lg);
  align-items: center;
  justify-content: center;
  gap: 160px;
}
.formaDePagamento1 {
  position: absolute;
  top: 15px;
  left: 18px;
  line-height: 15px;
}
.formaspagamento,
.vectorIcon31 {
  position: absolute;
  top: 18px;
  left: 245px;
  width: 16px;
  height: 9px;
}
.formaspagamento {
  top: 176px;
  left: 978px;
  border-radius: var(--br-3xs);
  background-color: var(--branco);
  border: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 278px;
  height: 45px;
  overflow: hidden;
}
.telaXloja03AreaparceirosChild2 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--preto-padro);
  width: 319px;
  height: 1080px;
}
.ellipseIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.feitoComAmor,
.feitocomamorxulizcombr {
  position: absolute;
  left: 50px;
  line-height: 15px;
}
.feitoComAmor {
  top: 5px;
  font-weight: 600;
}
.feitocomamorxulizcombr {
  top: 20px;
  font-size: var(--font-size-3xs);
}
.ellipseParent {
  position: absolute;
  top: 999px;
  left: 62px;
  width: 192px;
  height: 40px;
  font-size: var(--font-size-xs);
  color: var(--branco);
}
.lineIcon,
.vectorIcon32 {
  position: absolute;
  top: 963.5px;
  left: 61.5px;
  width: 166px;
  height: 1px;
}
.vectorIcon32 {
  top: -0.75px;
  left: -0.75px;
  width: 16.5px;
  height: 16.5px;
}
.configuraes {
  position: absolute;
  top: 0;
  left: 21px;
  line-height: 15px;
  font-weight: 600;
}
.vectorParent {
  position: absolute;
  top: 894px;
  left: 62px;
  width: 96px;
  height: 15px;
  font-size: var(--font-size-3xs);
  color: var(--branco);
}
.closeTracedIcon {
  position: absolute;
  height: 100%;
  width: 36.52%;
  top: 0;
  right: 63.48%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.closeTracedParent {
  position: absolute;
  height: 1.39%;
  width: 2.14%;
  top: 85.56%;
  right: 94.64%;
  bottom: 13.06%;
  left: 3.23%;
  cursor: pointer;
  font-size: var(--font-size-3xs);
  color: var(--branco);
}
.vectorIcon33 {
  position: absolute;
  top: 1.25px;
  left: -0.75px;
  width: 16.5px;
  height: 13.77px;
}
.vectorContainer,
.vectorGroup {
  position: absolute;
  top: 864px;
  left: 62px;
  width: 57px;
  height: 15px;
  font-size: var(--font-size-3xs);
  color: var(--branco);
}
.vectorContainer {
  top: 834px;
  width: 106px;
}
.groupChild12 {
  position: absolute;
  height: 13.25%;
  width: 1.22%;
  top: 22.37%;
  right: 99.39%;
  bottom: 64.38%;
  left: -0.61%;
  border-right: 1.5px solid var(--cor-xuliz);
  box-sizing: border-box;
}
.sentTracedIcon {
  position: absolute;
  height: 102.91%;
  width: 15.35%;
  top: -1.45%;
  right: 84.9%;
  bottom: -1.46%;
  left: -0.25%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.produtos {
  position: absolute;
  top: 5.82%;
  left: 29.7%;
  line-height: 15px;
  font-weight: 600;
}
.sentTracedParent,
.vectorIcon35 {
  position: absolute;
  height: 12.3%;
  width: 81.88%;
  top: 66.22%;
  right: 5.67%;
  bottom: 21.48%;
  left: 12.45%;
}
.vectorIcon35 {
  height: 109%;
  width: 21.43%;
  top: -4.5%;
  right: 79.55%;
  bottom: -4.5%;
  left: -0.97%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.home {
  position: absolute;
  top: 6%;
  left: 38.96%;
  line-height: 15px;
  font-weight: 600;
}
.vectorParent1 {
  position: absolute;
  height: 11.93%;
  width: 62.42%;
  top: 0;
  right: 25.13%;
  bottom: 88.07%;
  left: 12.45%;
  cursor: pointer;
}
.colorPaletteTraced {
  position: absolute;
  height: 102.08%;
  width: 14.35%;
  top: -1.65%;
  right: 85.88%;
  bottom: -0.43%;
  left: -0.23%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.relatrios {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 15px;
  font-weight: 600;
}
.relatriosWrapper {
  position: absolute;
  height: 98.79%;
  width: 72.22%;
  top: 1.21%;
  right: 0;
  bottom: 0;
  left: 27.78%;
}
.colorPaletteTracedParent,
.vectorIcon36 {
  position: absolute;
  height: 10.87%;
  width: 87.55%;
  top: 44.6%;
  right: 0;
  bottom: 44.52%;
  left: 12.45%;
}
.vectorIcon36 {
  height: 95.96%;
  width: 21.43%;
  top: 4.04%;
  right: 78.57%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.xloja {
  position: absolute;
  top: 0;
  left: 41.89%;
  line-height: 15px;
  font-weight: 600;
}
.vectorParent2 {
  position: absolute;
  height: 11.19%;
  width: 59.99%;
  top: 22.67%;
  right: 28.37%;
  bottom: 66.14%;
  left: 11.64%;
  color: var(--branco);
}
.financeTracedIcon {
  position: absolute;
  height: 100%;
  width: 18.97%;
  top: 0;
  right: 81.03%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.saque {
  position: absolute;
  top: 0;
  left: 37.97%;
  line-height: 15px;
  font-weight: 600;
}
.financeTracedParent,
.lineParent11 {
  position: absolute;
  height: 10.74%;
  width: 64.04%;
  top: 89.26%;
  right: 23.51%;
  bottom: 0;
  left: 12.45%;
}
.lineParent11 {
  height: 12.93%;
  width: 6.42%;
  top: 16.57%;
  right: 90.35%;
  bottom: 70.49%;
  left: 3.23%;
  color: var(--preto-c);
}
.logomarcaXulizIcon {
  position: absolute;
  top: 81px;
  left: calc(50% - 898px);
  width: 51.18px;
  height: 28px;
  overflow: hidden;
  object-fit: cover;
}
.parceirosIcon {
  position: absolute;
  top: 86px;
  left: 133.65px;
  width: 99.98px;
  height: 16.74px;
}
.telaXloja03AreaparceirosChild3 {
  position: absolute;
  top: 86.25px;
  left: 122.9px;
  width: 1.5px;
  height: 17.34px;
}
.telaXloja03Areaparceiros {
  position: relative;
  background-color: var(--branco);
  width: 100%;
  height: 1080px;
  overflow: hidden;
  text-align: left;
  font-size: var(--ttulos-cabealho-size);
  color: var(--cinza-padro);
  font-family: var(--ttulos-cabealho);
}
