.groupChild {
  position: absolute;
  top: 302px;
  left: -1px;
  border-top: 2px solid var(--preto-c);
  box-sizing: border-box;
  width: 1062px;
  height: 2px;
}
.faleComA,
.noLigamosNem {
  position: absolute;
  line-height: 15px;
}
.faleComA {
  top: 0;
  left: calc(50% - 182px);
  font-size: var(--font-size-xl);
  font-weight: 600;
  color: var(--cor-xuliz);
}
.noLigamosNem {
  top: 194px;
  left: 348px;
  display: inline-block;
  width: 627px;
  height: 29px;
}
.eMailParaParceria {
  margin: 0;
}
.parceriaxulizcombr {
  margin: 0;
  font-weight: 500;
}
.eMailParaParceriaContainer {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 15px;
}
.eMailParaParceriaParceriaWrapper {
  position: absolute;
  top: 40px;
  left: 348px;
  width: 135px;
  height: 30px;
}
.ouMandeUmContainer {
  position: absolute;
  top: 25px;
  left: 0;
  line-height: 15px;
}
.entrarEmContatoParent {
  position: absolute;
  top: 40px;
  left: 618px;
  width: 160px;
  height: 55px;
}
.precisaDeAjuda {
  position: absolute;
  top: 0;
  left: calc(50% + 88px);
  font-size: var(--font-size-xl);
  line-height: 15px;
  font-weight: 600;
  color: var(--cor-xuliz);
}
.polticaAssinaturaRecorrenteContainer {
  position: absolute;
  top: 50px;
  left: 0;
  line-height: 15px;
}
.polticaDePrivacidadeParent {
  position: absolute;
  top: 40px;
  left: 890px;
  width: 136px;
  height: 80px;
}
.baixeOXuliz,
.polticas,
.sigaOXuliz {
  position: absolute;
  top: 0;
  left: calc(50% + 360px);
  font-size: var(--font-size-xl);
  line-height: 15px;
  font-weight: 600;
  color: var(--cor-xuliz);
}
.baixeOXuliz,
.sigaOXuliz {
  left: calc(50% - 530px);
  display: flex;
  align-items: center;
  width: 171px;
}
.sigaOXuliz {
  top: 156px;
}
.appstoreBrancoPiylz2yjgagdbmIcon,
.googleplayBrancoPiylzccxcmt8Icon {
  position: absolute;
  top: 44.86px;
  left: 0;
  width: 118.52px;
  height: 34.86px;
  overflow: hidden;
  object-fit: cover;
}
.googleplayBrancoPiylzccxcmt8Icon {
  top: 0;
}
.appstoreBrancoPiylz2yjgagdbmParent {
  position: absolute;
  top: 40px;
  left: 0;
  width: 118.52px;
  height: 79.72px;
}
.aconselhamosQueTodos {
  position: absolute;
  top: 11px;
  left: 444px;
  letter-spacing: -0.27px;
  line-height: 15px;
  display: inline-block;
  width: 616px;
  height: 28px;
}
.logoBrancaPiykomro5dg3q4opx6Icon {
  position: absolute;
  top: calc(50% - 25px);
  left: 0;
  width: 94.44px;
  height: 50px;
  overflow: hidden;
  object-fit: cover;
}
.xulizAllRights {
  position: absolute;
  top: 18px;
  left: 160px;
  line-height: 15px;
}
.aconselhamosQueTodosOsUsuParent {
  position: absolute;
  top: 338px;
  left: 0;
  width: 1060px;
  height: 50px;
}
.instagram {
  position: absolute;
  top: 2.28px;
  left: 25.17px;
  line-height: 15px;
}
.instagramParent,
.vectorIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 20.17px;
  height: 20.17px;
}
.instagramParent {
  top: 196px;
  width: 87.17px;
}
.lineParent {
  position: relative;
  width: 1060px;
  height: 388px;
  flex-shrink: 0;
}
.section7 {
  position: absolute;
  top: 3688px;
  left: 1px;
  background-color: var(--preto-padro);
  width: 1917px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-81xl) var(--padding-356xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-xs);
}
.subtitulo01 {
  position: absolute;
  top: 48px;
  left: 0;
  line-height: 20px;
  font-weight: 600;
}
.sigaONosso {
  position: relative;
  line-height: 16px;
  font-weight: 600;
}
.saibamais {
  position: absolute;
  top: 0;
  left: 338px;
  border-radius: var(--br-8xs);
  background-color: var(--preto-c);
  width: 217px;
  height: 93px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-mini);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  color: var(--cor-xuliz);
}
.subtitulo011 {
  position: absolute;
  top: 7px;
  left: 43px;
  line-height: 20px;
  font-weight: 600;
}
.vectorIcon1 {
  position: absolute;
  height: 100%;
  width: 22.15%;
  top: 0;
  right: 77.85%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.subtitulo01Group,
.vectorIcon2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 149px;
  height: 33px;
}
.vectorIcon2 {
  height: 57.58%;
  width: 13.42%;
  top: 18.18%;
  right: 83.22%;
  bottom: 24.24%;
  left: 3.36%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.groupDiv,
.subtitulo01Parent {
  position: absolute;
  top: 0;
  left: 0;
}
.groupDiv {
  width: 149px;
  height: 33px;
  font-size: var(--font-size-xl);
}
.subtitulo01Parent {
  width: 555px;
  height: 93px;
}
.groupContainer {
  position: relative;
  width: 555px;
  height: 93px;
  flex-shrink: 0;
}
.groupWrapper,
.titulo01 {
  position: absolute;
  top: 0;
  left: 0;
}
.groupWrapper {
  border-radius: var(--br-3xs);
  border: 2px solid var(--preto-padro);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-11xl);
  align-items: center;
  justify-content: center;
}
.titulo01 {
  line-height: 32px;
  font-weight: 600;
}
.acompanheNossasRedesContainer {
  position: absolute;
  top: 79px;
  left: 0;
  font-size: var(--font-size-xl);
  line-height: 20px;
}
.titulo01Parent {
  position: absolute;
  top: 7px;
  left: 700px;
  width: 431px;
  height: 119px;
  font-size: var(--font-size-21xl);
}
.frameParent {
  left: 0;
  width: 1131px;
  height: 153px;
  font-size: var(--ttulos-cabealho-size);
}
.frameParent,
.icon,
.xulizApp {
  position: absolute;
  top: 0;
}
.icon {
  left: 690px;
  width: 480px;
  height: 426.89px;
  object-fit: cover;
}
.xulizApp {
  left: 0;
  line-height: 30px;
  display: flex;
  color: var(--color-gray-100);
  align-items: center;
  width: 102.14px;
  height: 25px;
}
.pagamentosRpidosEContainer {
  position: absolute;
  top: 25px;
  left: 0;
  font-size: var(--font-size-29xl);
  line-height: 48px;
}
.criaUmaContaContainer1 {
  line-break: anywhere;
  width: 100%;
}
.criaUmaContaContainer {
  position: absolute;
  top: 136px;
  left: 0;
  line-height: 19px;
  display: flex;
  align-items: center;
  width: 506.92px;
  height: 42px;
}
.groupFrame,
.parent,
.xulizAppParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 532px;
  height: 178px;
}
.groupFrame,
.parent {
  top: 124px;
}
.parent {
  top: 253px;
  width: 1170px;
  height: 426.89px;
}
.cashbackNaHora {
  font-weight: 600;
}
.emTodosOsContainer {
  position: absolute;
  top: 136px;
  left: 0;
  line-height: 19px;
  display: flex;
  align-items: center;
  width: 478px;
  height: 42px;
}
.groupWrapper2,
.vectorIcon3,
.xulizAppGroup {
  position: absolute;
  top: 0;
  left: 0;
  width: 556px;
  height: 178px;
}
.groupWrapper2,
.vectorIcon3 {
  top: 144px;
  left: 614px;
}
.vectorIcon3 {
  height: 100%;
  width: 41.03%;
  top: 0;
  right: 58.97%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.groupParent1,
.groupWrapper1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 1170px;
  height: 465.48px;
}
.groupWrapper1 {
  top: 779.89px;
}
.groupParent {
  position: relative;
  width: 1170px;
  height: 1245.37px;
  flex-shrink: 0;
}
.section6 {
  position: absolute;
  top: 2242px;
  left: 0;
  background-color: var(--cor-xuliz);
  display: flex;
  flex-direction: row;
  padding: var(--padding-81xl) var(--padding-356xl);
  align-items: center;
  justify-content: center;
  color: var(--preto-padro);
}
.imagem02SitePiynep9kd4k8s4mIcon {
  position: absolute;
  top: calc(50% - 237.93px);
  left: 0;
  width: 480px;
  height: 475.86px;
  overflow: hidden;
  object-fit: cover;
}
.issoSer {
  position: absolute;
  top: 0;
  left: 0;
  font-size: var(--font-size-21xl);
  line-height: 30px;
}
.subtitulo012,
.subtitulo013 {
  position: absolute;
  top: 35px;
  left: 0;
  line-height: 20px;
}
.subtitulo013 {
  top: 0;
  left: 33px;
  font-weight: 600;
}
.groupItem {
  position: absolute;
  height: 56.94%;
  width: 5.83%;
  top: 0.54%;
  right: 94.17%;
  bottom: 42.52%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.subtitulo01Container,
.vectorIcon4 {
  position: absolute;
  top: 145px;
  left: 0;
  width: 343px;
  height: 40px;
}
.vectorIcon4 {
  height: 99.92%;
  width: 10.99%;
  top: 0.08%;
  right: 89.01%;
  bottom: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.subtitulo01Parent1,
.vectorIcon5 {
  position: absolute;
  top: 0;
  left: 0;
  width: 182px;
  height: 20.06px;
}
.vectorIcon5 {
  height: 95.65%;
  width: 6.87%;
  top: 0.34%;
  right: 93.13%;
  bottom: 4.02%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.groupInner,
.subtitulo01Parent2 {
  position: absolute;
  top: 35px;
  left: 0;
  width: 291px;
  height: 20px;
}
.groupInner {
  height: 75.02%;
  width: 5.81%;
  top: 15.38%;
  right: 94.19%;
  bottom: 9.61%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.subtitulo01Parent3 {
  position: absolute;
  top: 70.13px;
  left: 0;
  width: 453px;
  height: 20px;
}
.subtitulo017 {
  position: absolute;
  top: 2.79px;
  left: 33px;
  line-height: 20px;
  font-weight: 600;
}
.groupIcon {
  position: absolute;
  height: 100%;
  width: 3.39%;
  top: 0;
  right: 96.61%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.subtitulo01Parent4 {
  position: absolute;
  top: 105.21px;
  left: 0;
  width: 428px;
  height: 25.01px;
}
.groupParent2,
.issoSerXulizParent {
  position: absolute;
  top: 100.04px;
  left: 0;
  width: 453px;
  height: 185px;
}
.issoSerXulizParent {
  top: 95px;
  left: 649px;
  height: 285.04px;
}
.imagem02SitePiynep9kd4k8s4mParent,
.section4Inner {
  position: absolute;
  top: calc(50% - 237.93px);
  left: 0;
  width: 1102px;
  height: 475.86px;
}
.section4Inner {
  top: calc(50% - 238px);
  left: 373px;
}
.section4 {
  position: absolute;
  top: 1566px;
  left: 0;
  width: 1920px;
  height: 676px;
  background-image: url(/public/section4@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  color: var(--preto-padro);
}
.vocSPrecisaContainer {
  position: absolute;
  top: 0;
  left: 30px;
  line-height: 20px;
}
.vectorIcon6 {
  position: absolute;
  height: 47.92%;
  width: 3.09%;
  top: 24.58%;
  right: 96.91%;
  bottom: 27.5%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.feito3,
.vectorIcon7 {
  position: absolute;
  top: 268px;
  left: 0;
  width: 647px;
  height: 40px;
}
.vectorIcon7 {
  height: 47.92%;
  width: 3.52%;
  top: 24.58%;
  right: 96.48%;
  bottom: 27.5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.feito2,
.vectorIcon8 {
  position: absolute;
  top: 203px;
  left: 0;
  width: 568px;
  height: 40px;
}
.vectorIcon8 {
  height: 47.92%;
  width: 3.37%;
  top: 24.58%;
  right: 96.63%;
  bottom: 27.5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.simplificarOProcessoEAindaParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 593px;
  height: 40px;
}
.feito1,
.titulo011 {
  position: absolute;
  left: 0;
}
.feito1 {
  top: 138px;
  width: 593px;
  height: 40px;
}
.titulo011 {
  top: 0;
  font-size: var(--font-size-21xl);
  letter-spacing: -1.66px;
  line-height: 40px;
}
.feito3Parent,
.groupChild1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 647px;
  height: 308px;
}
.groupChild1 {
  top: 9px;
  left: 922.25px;
  width: 245.25px;
  height: 290.61px;
}
.groupParent3 {
  position: relative;
  width: 1167.5px;
  height: 308px;
  flex-shrink: 0;
}
.section3 {
  position: absolute;
  top: 958px;
  left: 0;
  width: 1919px;
  display: flex;
  flex-direction: column;
  padding: 150px 379px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  background-image: url(/public/section3@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.nascemosParaMostrarContainer {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 40px;
}
.maisRpidaFcil {
  font-weight: 500;
  font-family: var(--ttulos-cabealho);
}
.oXulizEstContainer {
  position: absolute;
  top: 3px;
  left: 661.5px;
  font-size: var(--font-size-xl);
  line-height: 25px;
}
.nascemosParaMostrarONovoNParent {
  position: relative;
  width: 1179.5px;
  height: 80px;
  flex-shrink: 0;
}
.section2 {
  top: 758px;
  background-color: var(--branco);
  width: 1920px;
  display: flex;
  flex-direction: column;
  padding: 60px var(--padding-356xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-21xl);
  color: var(--preto-padro);
}
.info,
.saibamais1,
.section2 {
  position: absolute;
  left: 0;
}
.info {
  top: 349px;
  line-height: 15px;
  font-weight: 600;
  display: inline-block;
  width: 259px;
  height: 30px;
}
.saibamais1 {
  top: 303px;
  border-radius: var(--br-8xs);
  background-color: var(--preto-c);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-mini);
  align-items: center;
  justify-content: center;
  font-size: var(--ttulos-cabealho-size);
  color: var(--cor-xuliz);
}
.subtitulo018,
.titulo012 {
  position: absolute;
  top: 179px;
  left: 0;
  font-size: var(--font-size-xl);
  line-height: 23px;
}
.titulo012 {
  top: 0;
  font-size: var(--font-size-29xl);
  line-height: 48px;
  color: var(--cor-xuliz);
}
.groupWrapper5,
.infoParent,
.site1Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 409px;
  height: 379px;
}
.groupWrapper5,
.site1Icon {
  top: 62px;
}
.site1Icon {
  top: 0;
  left: 690px;
  width: 480px;
  height: 481.86px;
  object-fit: cover;
}
.groupParent4 {
  position: relative;
  width: 1170px;
  height: 481.86px;
  flex-shrink: 0;
}
.section1 {
  position: absolute;
  top: 116px;
  left: 0;
  background-color: var(--preto-padro);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 80px var(--padding-356xl);
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-xs);
}
.ajuda,
.oXuliz,
.parceiros {
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% + 55px);
  line-height: 16px;
  font-weight: 600;
}
.ajuda,
.oXuliz {
  left: calc(50% - 36px);
}
.oXuliz {
  left: calc(50% - 127px);
}
.parceirosParent {
  position: absolute;
  top: calc(50% - 7.98px);
  left: calc(50% - 400.96px);
  width: 254px;
  height: 16px;
}
.logomarcaXulizIcon {
  position: absolute;
  top: calc(50% - 22.98px);
  left: calc(50% - 584.96px);
  width: 84px;
  height: 45.96px;
  overflow: hidden;
  object-fit: cover;
}
.areaparceiros {
  position: absolute;
  top: calc(50% - 18px);
  left: calc(50% + 11.96px);
  border-radius: var(--br-8xs);
  border: 1px solid var(--cor-xuliz);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-mini);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.vectorIcon9 {
  position: relative;
  width: 11.92px;
  height: 11.92px;
  flex-shrink: 0;
}
.areaparceirosParent,
.baixeapp {
  position: absolute;
  top: calc(50% - 18px);
}
.baixeapp {
  left: calc(50% - 153.96px);
  border-radius: var(--br-8xs);
  background-color: var(--cor-xuliz);
  border: 1px solid var(--cor-xuliz);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-mini);
  align-items: center;
  justify-content: center;
  gap: 20px;
  color: var(--preto-padro);
}
.areaparceirosParent {
  left: calc(50% + 277.04px);
  width: 307.92px;
  height: 36px;
  color: var(--cor-xuliz);
}
.cabealhoChild {
  position: absolute;
  top: 40.5px;
  left: 506.5px;
  border-right: 1px solid var(--cor-xuliz);
  box-sizing: border-box;
  width: 1px;
  height: 36px;
}
.cabealho,
.desktop {
  background-color: var(--preto-padro);
  overflow: hidden;
}
.cabealho {
  position: absolute;
  top: 0;
  left: 2px;
  width: 1915.92px;
  height: 115.96px;
  font-size: var(--ttulos-cabealho-size);
}
.desktop {
  position: relative;
  width: 100%;
  height: 5791px;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--branco);
  font-family: var(--ttulos-cabealho);
}
