.div {
  position: relative;
  line-height: 15px;
  display: flex;
  align-items: center;
  width: 86px;
  height: 15px;
  flex-shrink: 0;
}
.defalut,
.defalut1,
.defalut2,
.defalut3 {
  position: absolute;
  height: 25%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 75%;
  left: 0;
  background-color: var(--branco);
  display: flex;
  flex-direction: row;
  padding: var(--padding-mini) var(--padding-31xl) var(--padding-mini)
    var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}
.defalut1,
.defalut2,
.defalut3 {
  top: 25%;
  bottom: 50%;
}
.defalut2,
.defalut3 {
  top: 50%;
  bottom: 25%;
}
.defalut3 {
  top: 75%;
  bottom: 0;
}
.listaBandeiras1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 278px;
  height: 180px;
}
.listaBandeiras {
  position: relative;
  border-radius: 0 0 var(--br-3xs) var(--br-3xs);
  border: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 100%;
  height: 180px;
  overflow: hidden;
  text-align: left;
  font-size: var(--ttulos-cabealho-size);
  color: var(--preto-padro);
  font-family: var(--ttulos-cabealho);
}
