.div {
  position: relative;
  line-height: 15px;
  display: flex;
  align-items: center;
  width: 86px;
  height: 15px;
  flex-shrink: 0;
}
.defalut,
.defalut1,
.defalut10,
.defalut11,
.defalut12,
.defalut13,
.defalut14,
.defalut15,
.defalut16,
.defalut17,
.defalut18,
.defalut19,
.defalut2,
.defalut20,
.defalut21,
.defalut22,
.defalut23,
.defalut3,
.defalut4,
.defalut5,
.defalut6,
.defalut7,
.defalut8,
.defalut9 {
  position: absolute;
  height: 11.11%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 88.89%;
  left: 0;
  background-color: var(--branco);
  border: 1px solid var(--color-darkgray-100);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: var(--padding-mini) var(--padding-31xl) var(--padding-mini)
    var(--padding-xl);
  align-items: center;
  justify-content: flex-start;
}
.defalut1,
.defalut10,
.defalut11,
.defalut12,
.defalut13,
.defalut14,
.defalut15,
.defalut16,
.defalut17,
.defalut18,
.defalut19,
.defalut2,
.defalut20,
.defalut21,
.defalut22,
.defalut23,
.defalut3,
.defalut4,
.defalut5,
.defalut6,
.defalut7,
.defalut8,
.defalut9 {
  top: 11.11%;
  bottom: 77.78%;
}
.defalut10,
.defalut11,
.defalut12,
.defalut13,
.defalut14,
.defalut15,
.defalut16,
.defalut17,
.defalut18,
.defalut19,
.defalut2,
.defalut20,
.defalut21,
.defalut22,
.defalut23,
.defalut3,
.defalut4,
.defalut5,
.defalut6,
.defalut7,
.defalut8,
.defalut9 {
  top: 22.22%;
  bottom: 66.67%;
}
.defalut10,
.defalut11,
.defalut12,
.defalut13,
.defalut14,
.defalut15,
.defalut16,
.defalut17,
.defalut18,
.defalut19,
.defalut20,
.defalut21,
.defalut22,
.defalut23,
.defalut3,
.defalut4,
.defalut5,
.defalut6,
.defalut7,
.defalut8,
.defalut9 {
  top: 33.33%;
  bottom: 55.56%;
}
.defalut10,
.defalut11,
.defalut12,
.defalut13,
.defalut14,
.defalut15,
.defalut16,
.defalut17,
.defalut18,
.defalut19,
.defalut20,
.defalut21,
.defalut22,
.defalut23,
.defalut4,
.defalut5,
.defalut6,
.defalut7,
.defalut8,
.defalut9 {
  top: 44.44%;
  bottom: 44.44%;
}
.defalut10,
.defalut11,
.defalut12,
.defalut13,
.defalut14,
.defalut15,
.defalut16,
.defalut17,
.defalut18,
.defalut19,
.defalut20,
.defalut21,
.defalut22,
.defalut23,
.defalut5,
.defalut6,
.defalut7,
.defalut8,
.defalut9 {
  top: 55.56%;
  bottom: 33.33%;
}
.defalut10,
.defalut11,
.defalut12,
.defalut13,
.defalut14,
.defalut15,
.defalut16,
.defalut17,
.defalut18,
.defalut19,
.defalut20,
.defalut21,
.defalut22,
.defalut23,
.defalut6,
.defalut7,
.defalut8,
.defalut9 {
  top: 66.67%;
  bottom: 22.22%;
}
.defalut10,
.defalut11,
.defalut12,
.defalut13,
.defalut14,
.defalut15,
.defalut16,
.defalut17,
.defalut18,
.defalut19,
.defalut20,
.defalut21,
.defalut22,
.defalut23,
.defalut7,
.defalut8,
.defalut9 {
  top: 77.78%;
  bottom: 11.11%;
}
.defalut10,
.defalut11,
.defalut12,
.defalut13,
.defalut14,
.defalut15,
.defalut16,
.defalut17,
.defalut18,
.defalut19,
.defalut20,
.defalut21,
.defalut22,
.defalut23,
.defalut8,
.defalut9 {
  top: 88.89%;
  bottom: 0;
}
.defalut10,
.defalut11,
.defalut12,
.defalut13,
.defalut14,
.defalut15,
.defalut16,
.defalut17,
.defalut18,
.defalut19,
.defalut20,
.defalut21,
.defalut22,
.defalut23,
.defalut9 {
  top: 100%;
  bottom: -11.11%;
}
.defalut10,
.defalut11,
.defalut12,
.defalut13,
.defalut14,
.defalut15,
.defalut16,
.defalut17,
.defalut18,
.defalut19,
.defalut20,
.defalut21,
.defalut22,
.defalut23 {
  top: 111.11%;
  bottom: -22.22%;
}
.defalut11,
.defalut12,
.defalut13,
.defalut14,
.defalut15,
.defalut16,
.defalut17,
.defalut18,
.defalut19,
.defalut20,
.defalut21,
.defalut22,
.defalut23 {
  top: 122.22%;
  bottom: -33.33%;
}
.defalut12,
.defalut13,
.defalut14,
.defalut15,
.defalut16,
.defalut17,
.defalut18,
.defalut19,
.defalut20,
.defalut21,
.defalut22,
.defalut23 {
  top: 133.33%;
  bottom: -44.44%;
}
.defalut13,
.defalut14,
.defalut15,
.defalut16,
.defalut17,
.defalut18,
.defalut19,
.defalut20,
.defalut21,
.defalut22,
.defalut23 {
  top: 144.44%;
  bottom: -55.56%;
}
.defalut14,
.defalut15,
.defalut16,
.defalut17,
.defalut18,
.defalut19,
.defalut20,
.defalut21,
.defalut22,
.defalut23 {
  top: 155.56%;
  bottom: -66.67%;
}
.defalut15,
.defalut16,
.defalut17,
.defalut18,
.defalut19,
.defalut20,
.defalut21,
.defalut22,
.defalut23 {
  top: 166.67%;
  bottom: -77.78%;
}
.defalut16,
.defalut17,
.defalut18,
.defalut19,
.defalut20,
.defalut21,
.defalut22,
.defalut23 {
  top: 177.78%;
  bottom: -88.89%;
}
.defalut17,
.defalut18,
.defalut19,
.defalut20,
.defalut21,
.defalut22,
.defalut23 {
  top: 188.89%;
  bottom: -100%;
}
.defalut18,
.defalut19,
.defalut20,
.defalut21,
.defalut22,
.defalut23 {
  top: 200%;
  bottom: -111.11%;
}
.defalut19,
.defalut20,
.defalut21,
.defalut22,
.defalut23 {
  top: 211.11%;
  bottom: -122.22%;
}
.defalut20,
.defalut21,
.defalut22,
.defalut23 {
  top: 222.22%;
  bottom: -133.33%;
}
.defalut21,
.defalut22,
.defalut23 {
  top: 233.33%;
  bottom: -144.44%;
}
.defalut22,
.defalut23 {
  top: 244.44%;
  bottom: -155.56%;
}
.defalut23 {
  top: 255.56%;
  bottom: -166.67%;
}
.lista,
.listaHora {
  border: 1px solid var(--cinza-padro);
  box-sizing: border-box;
}
.lista {
  position: absolute;
  top: 0;
  left: 0;
  width: 271px;
  height: 405px;
}
.listaHora {
  position: relative;
  border-radius: 0 0 var(--br-3xs) var(--br-3xs);
  background-color: var(--branco);
  width: 100%;
  height: 135px;
  overflow-y: auto;
  text-align: left;
  font-size: var(--ttulos-cabealho-size);
  color: var(--preto-padro);
  font-family: var(--ttulos-cabealho);
}
