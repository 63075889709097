.div,
.realizarVenda,
.vendasDeHoje {
  position: absolute;
  top: 97px;
  line-height: 15px;
}
.realizarVenda {
  left: 602px;
  cursor: pointer;
}
.div,
.vendasDeHoje {
  color: var(--preto-padro);
}
.div {
  left: 1662px;
  font-size: var(--font-size-xl);
  font-weight: 600;
}
.vendasDeHoje {
  left: 750px;
}
.calendrio,
.histricoDeVendas {
  position: absolute;
  top: 97px;
  left: 904px;
  line-height: 15px;
  cursor: pointer;
}
.calendrio {
  left: 1091px;
}
.telaXloja02AreaparceirosChild {
  position: absolute;
  height: 4.88%;
  width: 6.25%;
  top: 7.22%;
  right: 73.33%;
  bottom: 87.9%;
  left: 20.42%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.telaXloja02AreaparceirosItem {
  position: absolute;
  top: 77.5px;
  left: 556.5px;
  width: 1px;
  height: 54px;
}
.filtrosSelecionados,
.horrio {
  position: absolute;
  top: 28.24%;
  left: 20.42%;
  line-height: 20.72px;
}
.filtrosSelecionados {
  top: 22.31%;
  font-weight: 600;
}
.bandeira,
.formaDePagamento,
.nmeroDoCarto,
.tid {
  position: absolute;
  top: 28.24%;
  left: 25.16%;
  line-height: 20.72px;
}
.formaDePagamento,
.nmeroDoCarto,
.tid {
  left: 30.52%;
}
.nmeroDoCarto,
.tid {
  left: 40.94%;
}
.tid {
  left: 49.95%;
}
.cashback,
.cdDeAturoizao,
.nsudoc,
.status,
.valorDaVenda {
  position: absolute;
  top: 28.24%;
  left: 61.35%;
  line-height: 20.72px;
}
.cashback,
.nsudoc,
.status,
.valorDaVenda {
  left: 71.67%;
}
.cashback,
.status,
.valorDaVenda {
  left: 77.08%;
}
.cashback,
.status {
  top: 28.33%;
  left: 84.43%;
}
.status {
  top: 28.43%;
  left: 90.89%;
}
.telaXloja02AreaparceirosInner {
  position: absolute;
  top: 339.5px;
  left: 391.5px;
  border-top: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 1449px;
  height: 1px;
}
.div1,
.div2,
.ql090bcoc,
.r21600,
.xuliz {
  position: absolute;
  top: 11px;
  left: 1088px;
  line-height: 20.72px;
}
.div1,
.div2,
.ql090bcoc,
.xuliz {
  left: 984px;
}
.div2,
.ql090bcoc,
.xuliz {
  top: 10px;
  left: 786px;
}
.ql090bcoc,
.xuliz {
  left: 568px;
}
.xuliz {
  left: 194px;
}
.bandeirasChild,
.div3 {
  position: absolute;
  left: 0;
}
.div3 {
  top: 10px;
  line-height: 20.72px;
}
.bandeirasChild {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--br-10xs);
  background-color: var(--preto-padro);
}
.bandeirasItem {
  position: absolute;
  height: 63.33%;
  width: 65.45%;
  top: 20%;
  right: 16.36%;
  bottom: 16.67%;
  left: 18.18%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.bandeiras {
  position: absolute;
  top: 0;
  left: 91px;
  width: 55px;
  height: 30px;
}
.div4 {
  position: absolute;
  top: 10px;
  left: 396px;
  line-height: 20.72px;
}
.groupChild {
  position: absolute;
  top: 54.5px;
  left: -0.5px;
  border-top: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 1449px;
  height: 1px;
}
.aprovado {
  position: absolute;
  height: 66.67%;
  width: 80.21%;
  top: 13.33%;
  left: 19.79%;
  line-height: 20.72px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.vectorIcon {
  position: absolute;
  height: 93.33%;
  width: 14.97%;
  top: 1.67%;
  right: 84.76%;
  bottom: 5%;
  left: 0.27%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.aprovada {
  position: absolute;
  top: 10px;
  left: 1353px;
  width: 93.5px;
  height: 15px;
  color: var(--verde);
}
.r2592 {
  position: relative;
  line-height: 20.72px;
}
.vectorIcon1 {
  position: relative;
  width: 9px;
  height: 9px;
  flex-shrink: 0;
}
.r2592Parent {
  position: absolute;
  top: 12px;
  left: 1229px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-8xs);
}
.r21600Parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 1448px;
  height: 55px;
}
.groupItem {
  border-radius: var(--br-10xs);
  background-color: var(--color-gray-200);
}
.groupItem,
.rectangleParent {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.groupDiv,
.r21600Container,
.r21600Group {
  position: absolute;
  top: 80px;
  left: 0;
  width: 1448px;
  height: 55px;
}
.groupDiv,
.r21600Container {
  top: 160px;
}
.groupDiv {
  top: 240px;
}
.r216004 {
  position: absolute;
  top: 11px;
  left: 1088px;
  text-decoration: line-through;
  line-height: 20.72px;
}
.groupChild4 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-10xs);
  background-color: #231f20;
}
.groupChild5 {
  position: absolute;
  height: 80%;
  width: 81.82%;
  top: 10%;
  right: 9.09%;
  bottom: 10%;
  left: 9.09%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.rejeitado {
  position: absolute;
  top: 11.76%;
  left: 20%;
  line-height: 20.72px;
  font-weight: 600;
}
.vectorIcon8 {
  position: absolute;
  height: 82.35%;
  width: 15.14%;
  top: 1.47%;
  right: 84.59%;
  bottom: 16.18%;
  left: 0.27%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.aprovada4 {
  position: absolute;
  top: 10px;
  left: 1353px;
  width: 92.5px;
  height: 17px;
  color: var(--vermelho);
}
.r25924 {
  position: relative;
  text-decoration: line-through;
  line-height: 20.72px;
}
.groupChild7,
.r21600Parent1 {
  position: absolute;
  top: 320px;
  left: 0;
  width: 1448px;
  height: 55px;
}
.groupChild7 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--br-10xs);
  background-color: #051f51;
}
.groupChild8 {
  position: absolute;
  height: 70%;
  width: 67.27%;
  top: 16.67%;
  right: 16.36%;
  bottom: 13.33%;
  left: 16.36%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.estornado {
  position: absolute;
  top: 13.33%;
  left: 18.78%;
  line-height: 20.72px;
  font-weight: 600;
}
.vectorIcon10 {
  position: absolute;
  height: 93.33%;
  width: 14.21%;
  top: 1.67%;
  right: 85.53%;
  bottom: 5%;
  left: 0.25%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.aprovada5 {
  position: absolute;
  top: 10px;
  left: 1353px;
  width: 98.5px;
  height: 15px;
  color: var(--vermelho);
}
.r21600Parent2,
.r21600Parent3 {
  position: absolute;
  top: 400px;
  left: 0;
  width: 1451.5px;
  height: 55px;
}
.r21600Parent3 {
  top: 480px;
  width: 1448px;
}
.pendente {
  position: absolute;
  top: 13.33%;
  left: 19.15%;
  line-height: 20.72px;
  font-weight: 600;
}
.aprovadaChild {
  position: absolute;
  height: 93.33%;
  width: 14.89%;
  top: 1.67%;
  right: 84.84%;
  bottom: 5%;
  left: 0.27%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.aprovada7 {
  position: absolute;
  top: 10px;
  left: 1353px;
  width: 94px;
  height: 15px;
  color: var(--laranja);
}
.r21600Parent4,
.vectorIcon15 {
  position: absolute;
  top: 560px;
  left: 0;
  width: 1448px;
  height: 55px;
}
.vectorIcon15 {
  height: 83.33%;
  width: 14.97%;
  top: 1.67%;
  right: 84.76%;
  bottom: 15%;
  left: 0.27%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vectorIcon16 {
  position: relative;
  width: 9px;
  height: 7.5px;
  flex-shrink: 0;
}
.r21600Parent5,
.vectorIcon17 {
  position: absolute;
  top: 640px;
  left: 0;
  width: 1448px;
  height: 55px;
}
.vectorIcon17 {
  height: 83.33%;
  width: 13.37%;
  top: 6.67%;
  right: 85.56%;
  bottom: 10%;
  left: 1.07%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vectorIcon18 {
  position: relative;
  width: 7px;
  height: 7px;
  flex-shrink: 0;
}
.r21600Parent6 {
  position: absolute;
  top: 720px;
  left: 0;
  width: 1448px;
  height: 55px;
}
.groupParent,
.r21600Parent7,
.r21600Parent8,
.r21600Parent9 {
  position: absolute;
  top: 800px;
  left: 0;
  width: 1448px;
  height: 55px;
}
.groupParent,
.r21600Parent8,
.r21600Parent9 {
  top: 880px;
}
.groupParent,
.r21600Parent9 {
  top: 960px;
}
.groupParent {
  top: 365px;
  left: 392px;
  width: 1478px;
  height: 660px;
  overflow-y: auto;
  color: var(--preto-padro);
}
.horrioDeVenda {
  position: relative;
  line-height: 20.72px;
  background: linear-gradient(180deg, #494949, #3d3d3d 99.98%, #3d3d3d 99.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.vectorIcon25 {
  position: relative;
  width: 6.66px;
  height: 6.66px;
  flex-shrink: 0;
}
.horrioDeVendaFim03Parent,
.horrioDeVendaIncio01Parent {
  position: absolute;
  top: 236px;
  left: 564px;
  border-radius: var(--br-8xs);
  background-color: var(--cor-xuliz);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-8xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
  font-size: var(--font-size-3xs);
}
.horrioDeVendaFim03Parent {
  left: 736.66px;
}
.groupChild17 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--branco);
  border: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 278px;
  height: 45px;
}
.nmeroDoCarto1 {
  position: absolute;
  top: 14.68px;
  left: 20px;
  line-height: 15px;
}
.rectangleParent2,
.telaXloja02AreaparceirosChild1 {
  position: absolute;
  top: 176px;
  left: 975px;
  width: 278px;
  height: 45px;
}
.telaXloja02AreaparceirosChild1 {
  top: 175.68px;
  left: 1562px;
  border-radius: var(--br-3xs);
  background-color: var(--branco);
  border: 1px solid var(--cinza-padro);
  box-sizing: border-box;
}
.cdigoDeAutorizao {
  position: absolute;
  top: 190.68px;
  left: 1579px;
  line-height: 15px;
}
.horrioDeVenda2 {
  position: relative;
  line-height: 15px;
}
.vectorIcon27 {
  position: relative;
  width: 16px;
  height: 9px;
  flex-shrink: 0;
}
.defalut,
.defalut02 {
  position: absolute;
  top: 176px;
  left: 392px;
  border-radius: var(--br-3xs);
  background-color: var(--branco);
  border: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 271px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-mini) var(--padding-xl);
  align-items: center;
  justify-content: flex-start;
  gap: 35px;
}
.defalut02 {
  left: 684px;
  gap: 48px;
}
.formaDePagamento1 {
  position: absolute;
  top: 15px;
  left: 20px;
  line-height: 15px;
}
.defalut021,
.vectorIcon29 {
  position: absolute;
  top: 18px;
  left: 235px;
  width: 16px;
  height: 9px;
}
.defalut021 {
  top: 176px;
  left: 1273px;
  border-radius: var(--br-3xs);
  background-color: var(--branco);
  border: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 271px;
  height: 45px;
}
.telaXloja02AreaparceirosChild2 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--preto-padro);
  width: 319px;
  height: 1080px;
}
.ellipseIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.feitoComAmor,
.feitocomamorxulizcombr {
  position: absolute;
  left: 50px;
  line-height: 15px;
}
.feitoComAmor {
  top: 5px;
  font-weight: 600;
}
.feitocomamorxulizcombr {
  top: 20px;
  font-size: var(--font-size-3xs);
}
.ellipseParent {
  position: absolute;
  top: 999px;
  left: 62px;
  width: 192px;
  height: 40px;
  font-size: var(--font-size-xs);
  color: var(--branco);
}
.lineIcon,
.vectorIcon30 {
  position: absolute;
  top: 963.5px;
  left: 61.5px;
  width: 166px;
  height: 1px;
}
.vectorIcon30 {
  top: -0.75px;
  left: -0.75px;
  width: 16.5px;
  height: 16.5px;
}
.configuraes {
  position: absolute;
  top: 0;
  left: 21px;
  line-height: 15px;
  font-weight: 600;
}
.vectorParent {
  position: absolute;
  top: 894px;
  left: 62px;
  width: 96px;
  height: 15px;
  font-size: var(--font-size-3xs);
  color: var(--branco);
}
.closeTracedIcon {
  position: absolute;
  height: 100%;
  width: 36.52%;
  top: 0;
  right: 63.48%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.closeTracedParent {
  position: absolute;
  height: 1.39%;
  width: 2.14%;
  top: 85.56%;
  right: 94.64%;
  bottom: 13.06%;
  left: 3.23%;
  cursor: pointer;
  font-size: var(--font-size-3xs);
  color: var(--branco);
}
.vectorIcon31 {
  position: absolute;
  top: 1.25px;
  left: -0.75px;
  width: 16.5px;
  height: 13.77px;
}
.vectorContainer,
.vectorGroup {
  position: absolute;
  top: 864px;
  left: 62px;
  width: 57px;
  height: 15px;
  font-size: var(--font-size-3xs);
  color: var(--branco);
}
.vectorContainer {
  top: 834px;
  width: 106px;
}
.groupChild18 {
  position: absolute;
  height: 13.25%;
  width: 1.22%;
  top: 22.37%;
  right: 99.39%;
  bottom: 64.38%;
  left: -0.61%;
  border-right: 1.5px solid var(--cor-xuliz);
  box-sizing: border-box;
}
.sentTracedIcon {
  position: absolute;
  height: 102.91%;
  width: 15.35%;
  top: -1.45%;
  right: 84.9%;
  bottom: -1.45%;
  left: -0.25%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.produtos {
  position: absolute;
  top: 5.82%;
  left: 29.7%;
  line-height: 15px;
  font-weight: 600;
}
.sentTracedParent,
.vectorIcon33 {
  position: absolute;
  height: 12.3%;
  width: 81.88%;
  top: 66.22%;
  right: 5.67%;
  bottom: 21.48%;
  left: 12.45%;
}
.vectorIcon33 {
  height: 109%;
  width: 21.43%;
  top: -4.5%;
  right: 79.55%;
  bottom: -4.5%;
  left: -0.97%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.home {
  position: absolute;
  top: 6%;
  left: 38.96%;
  line-height: 15px;
  font-weight: 600;
}
.vectorParent1 {
  position: absolute;
  height: 11.93%;
  width: 62.42%;
  top: 0;
  right: 25.13%;
  bottom: 88.07%;
  left: 12.45%;
  cursor: pointer;
}
.colorPaletteTraced {
  position: absolute;
  height: 102.08%;
  width: 14.35%;
  top: -1.65%;
  right: 85.88%;
  bottom: -0.43%;
  left: -0.23%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.relatrios {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 15px;
  font-weight: 600;
}
.relatriosWrapper {
  position: absolute;
  height: 98.79%;
  width: 72.22%;
  top: 1.21%;
  right: 0;
  bottom: 0;
  left: 27.78%;
}
.colorPaletteTracedParent,
.vectorIcon34 {
  position: absolute;
  height: 10.87%;
  width: 87.55%;
  top: 44.6%;
  right: 0;
  bottom: 44.52%;
  left: 12.45%;
}
.vectorIcon34 {
  height: 95.96%;
  width: 21.43%;
  top: 4.04%;
  right: 78.57%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.xloja {
  position: absolute;
  top: 0;
  left: 41.89%;
  line-height: 15px;
  font-weight: 600;
}
.vectorParent2 {
  position: absolute;
  height: 11.19%;
  width: 59.99%;
  top: 22.67%;
  right: 28.37%;
  bottom: 66.14%;
  left: 11.64%;
  color: var(--branco);
}
.financeTracedIcon {
  position: absolute;
  height: 100%;
  width: 18.97%;
  top: 0;
  right: 81.03%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.saque {
  position: absolute;
  top: 0;
  left: 37.97%;
  line-height: 15px;
  font-weight: 600;
}
.financeTracedParent,
.lineParent {
  position: absolute;
  height: 10.74%;
  width: 64.04%;
  top: 89.26%;
  right: 23.51%;
  bottom: 0;
  left: 12.45%;
}
.lineParent {
  height: 12.93%;
  width: 6.42%;
  top: 16.57%;
  right: 90.35%;
  bottom: 70.49%;
  left: 3.23%;
  color: var(--preto-c);
}
.logomarcaXulizIcon {
  position: absolute;
  top: 81px;
  left: calc(50% - 898px);
  width: 51.18px;
  height: 28px;
  overflow: hidden;
  object-fit: cover;
}
.parceirosIcon {
  position: absolute;
  top: 86px;
  left: 133.65px;
  width: 99.98px;
  height: 16.74px;
}
.telaXloja02AreaparceirosChild3 {
  position: absolute;
  top: 86.25px;
  left: 122.9px;
  width: 1.5px;
  height: 17.34px;
}
.telaXloja02Areaparceiros {
  position: relative;
  background-color: var(--branco);
  width: 100%;
  height: 1080px;
  overflow: hidden;
  text-align: left;
  font-size: var(--ttulos-cabealho-size);
  color: var(--cinza-padro);
  font-family: var(--ttulos-cabealho);
}
