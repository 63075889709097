.calenderChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs) var(--br-3xs) 0 0;
  background-color: var(--preto-c);
  border: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 806px;
  height: 45px;
}
.timeday1w4i,
.timeday1w4i1,
.timeday1w4i2,
.timeday1w4i3,
.timeday1w4i4,
.timeday1w4i5,
.timeday1w4i6,
.timeday1w4i7,
.timeday1w4i8 {
  position: absolute;
  top: 45px;
  left: 0;
  border-right: 1px solid var(--cinza-padro);
  border-bottom: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 116px;
  height: 120px;
}
.timeday1w4i1,
.timeday1w4i2,
.timeday1w4i3,
.timeday1w4i4,
.timeday1w4i5,
.timeday1w4i6,
.timeday1w4i7,
.timeday1w4i8 {
  top: 643px;
  left: 230px;
}
.timeday1w4i2,
.timeday1w4i3,
.timeday1w4i4,
.timeday1w4i5,
.timeday1w4i6,
.timeday1w4i7,
.timeday1w4i8 {
  left: 345px;
}
.timeday1w4i3,
.timeday1w4i4,
.timeday1w4i5,
.timeday1w4i6,
.timeday1w4i7,
.timeday1w4i8 {
  left: 460px;
}
.timeday1w4i4,
.timeday1w4i5,
.timeday1w4i6,
.timeday1w4i7,
.timeday1w4i8 {
  left: 575px;
}
.timeday1w4i5,
.timeday1w4i6,
.timeday1w4i7,
.timeday1w4i8 {
  top: 45px;
  left: 115px;
}
.timeday1w4i6,
.timeday1w4i7,
.timeday1w4i8 {
  left: 230px;
}
.timeday1w4i7,
.timeday1w4i8 {
  left: 345px;
}
.timeday1w4i8 {
  left: 460px;
}
.div {
  position: absolute;
  top: 19px;
  left: 12px;
  line-height: 14px;
  color: var(--cinza-padro);
}
.div,
.span {
  font-weight: 500;
}
.span1 {
  font-weight: 600;
}
.r1004442,
.vendas {
  position: absolute;
  top: 74px;
  left: 12px;
  line-height: 14px;
}
.vendas {
  top: 91px;
  font-size: var(--font-size-2xs);
}
.dayCalender,
.dayCalender1,
.dayCalender10,
.dayCalender2,
.dayCalender3,
.dayCalender4,
.dayCalender5,
.dayCalender6,
.dayCalender7,
.dayCalender8,
.dayCalender9 {
  position: absolute;
  top: 0;
  left: 115px;
  border-right: 1px solid var(--cinza-padro);
  border-bottom: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 116px;
  height: 120px;
}
.dayCalender1,
.dayCalender10,
.dayCalender2,
.dayCalender3,
.dayCalender4,
.dayCalender5,
.dayCalender6,
.dayCalender7,
.dayCalender8,
.dayCalender9 {
  top: 120px;
  height: 119px;
}
.dayCalender10,
.dayCalender2,
.dayCalender3,
.dayCalender4,
.dayCalender5,
.dayCalender6,
.dayCalender7,
.dayCalender8,
.dayCalender9 {
  left: 0;
}
.dayCalender10,
.dayCalender3,
.dayCalender4,
.dayCalender5,
.dayCalender6,
.dayCalender7,
.dayCalender8,
.dayCalender9 {
  left: -115px;
}
.dayCalender10,
.dayCalender4,
.dayCalender5,
.dayCalender6,
.dayCalender7,
.dayCalender8,
.dayCalender9 {
  left: -230px;
}
.dayCalender10,
.dayCalender5,
.dayCalender6,
.dayCalender7,
.dayCalender8,
.dayCalender9 {
  left: -345px;
}
.dayCalender10,
.dayCalender6,
.dayCalender7,
.dayCalender8,
.dayCalender9 {
  left: -460px;
}
.dayCalender10,
.dayCalender7,
.dayCalender8,
.dayCalender9 {
  left: -575px;
}
.dayCalender10,
.dayCalender8,
.dayCalender9 {
  top: 239px;
  left: 115px;
  height: 120px;
}
.dayCalender10,
.dayCalender9 {
  top: 359px;
}
.dayCalender10 {
  top: 479px;
  height: 119px;
}
.dayCalender11,
.dayCalender12,
.dayCalender13,
.dayCalender14,
.dayCalender15,
.dayCalender16,
.dayCalender17,
.dayCalender18,
.dayCalender19,
.dayCalender20,
.dayCalender21 {
  position: absolute;
  top: 239px;
  left: 0;
  border-right: 1px solid var(--cinza-padro);
  border-bottom: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 116px;
  height: 120px;
}
.dayCalender12,
.dayCalender13,
.dayCalender14,
.dayCalender15,
.dayCalender16,
.dayCalender17,
.dayCalender18,
.dayCalender19,
.dayCalender20,
.dayCalender21 {
  top: 359px;
}
.dayCalender13,
.dayCalender14,
.dayCalender15,
.dayCalender16,
.dayCalender17,
.dayCalender18,
.dayCalender19,
.dayCalender20,
.dayCalender21 {
  top: 479px;
  height: 119px;
}
.dayCalender14,
.dayCalender15,
.dayCalender16,
.dayCalender17,
.dayCalender18,
.dayCalender19,
.dayCalender20,
.dayCalender21 {
  top: 239px;
  left: -115px;
  height: 120px;
}
.dayCalender15,
.dayCalender16,
.dayCalender17,
.dayCalender18,
.dayCalender19,
.dayCalender20,
.dayCalender21 {
  top: 359px;
}
.dayCalender16,
.dayCalender17,
.dayCalender18,
.dayCalender19,
.dayCalender20,
.dayCalender21 {
  top: 479px;
  height: 119px;
}
.dayCalender17,
.dayCalender18,
.dayCalender19,
.dayCalender20,
.dayCalender21 {
  top: 239px;
  left: -230px;
  height: 120px;
}
.dayCalender18,
.dayCalender19,
.dayCalender20,
.dayCalender21 {
  top: 359px;
}
.dayCalender19,
.dayCalender20,
.dayCalender21 {
  top: 479px;
  height: 119px;
}
.dayCalender20,
.dayCalender21 {
  top: 239px;
  left: -345px;
  height: 120px;
}
.dayCalender21 {
  top: 359px;
}
.dayCalender22,
.dayCalender23,
.dayCalender24,
.dayCalender25,
.dayCalender26,
.dayCalender27,
.dayCalender28,
.dayCalender29,
.dayCalender30,
.timeday1w4i9 {
  position: absolute;
  top: 479px;
  left: -345px;
  border-right: 1px solid var(--cinza-padro);
  border-bottom: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 116px;
  height: 119px;
}
.dayCalender23,
.dayCalender24,
.dayCalender25,
.dayCalender26,
.dayCalender27,
.dayCalender28,
.dayCalender29,
.dayCalender30,
.timeday1w4i9 {
  top: 239px;
  left: -460px;
  height: 120px;
}
.dayCalender24,
.dayCalender25,
.dayCalender26,
.dayCalender27,
.dayCalender28,
.dayCalender29,
.dayCalender30,
.timeday1w4i9 {
  top: 359px;
}
.dayCalender25,
.dayCalender26,
.dayCalender27,
.dayCalender28,
.dayCalender29,
.dayCalender30,
.timeday1w4i9 {
  top: 479px;
  height: 119px;
}
.dayCalender26,
.dayCalender27,
.dayCalender28,
.dayCalender29,
.dayCalender30,
.timeday1w4i9 {
  top: 598px;
  height: 120px;
}
.dayCalender27,
.dayCalender28,
.dayCalender29,
.dayCalender30,
.timeday1w4i9 {
  top: 239px;
  left: -575px;
}
.dayCalender28,
.dayCalender29,
.dayCalender30,
.timeday1w4i9 {
  top: 359px;
}
.dayCalender29,
.dayCalender30,
.timeday1w4i9 {
  top: 479px;
  height: 119px;
}
.dayCalender30,
.timeday1w4i9 {
  top: 598px;
  height: 120px;
}
.timeday1w4i9 {
  top: 45px;
  left: 575px;
  text-align: left;
  font-size: var(--font-size-smi);
  color: var(--preto-c);
}
.dom,
.qua,
.qui,
.sb,
.seg,
.sex,
.ter {
  position: absolute;
  top: 15px;
  left: 39px;
  line-height: 14px;
  font-weight: 600;
}
.qua,
.qui,
.sb,
.seg,
.sex,
.ter {
  left: 158px;
}
.qua,
.qui,
.sb,
.sex,
.ter {
  left: 276px;
}
.qua,
.qui,
.sb,
.sex {
  left: 387px;
}
.qui,
.sb,
.sex {
  left: 504px;
}
.sb,
.sex {
  left: 619px;
}
.sb {
  left: 734px;
}
.calender {
  position: relative;
  border-radius: var(--br-3xs);
  background-color: var(--branco);
  border: 1px solid var(--cinza-padro);
  box-sizing: border-box;
  width: 100%;
  height: 763px;
  overflow: hidden;
  text-align: center;
  font-size: var(--ttulos-cabealho-size);
  color: var(--cor-xuliz);
  font-family: var(--ttulos-cabealho);
}
