/*Responsivo-Laptop*/

@media (max-width: 1024px) {

    .container-principal{
        margin: 0;
        padding: 0;
        height: 750px;
    }

    .container-valores{
        transform: scale(0.63);
        display: flex;
        justify-content: center;
    }

    .valor-total{
        margin: 0;
        padding: 0;
    }

    .container-lancamentos{
        transform: scale(0.63); 
        display: flex;
        justify-content: center;
        transform-origin: top;
    }

    .consultar-recebiveis-mobile{
        display: none;
    }
}

/*Responsivo-Tablet*/

@media (max-width: 768px) {
    .container-principal-mobile{
        margin: 0;
        padding: 0;
        height: 820px; 
    }

    .container-valores-mobile{
        transform: scale(0.63);
        display: flex;
        justify-content: center;
    }

    .valor-total-mobile{
        margin: 0;
        padding: 0;
    }

    .container-lancamentos-mobile{
        transform: scale(0.76); 
        display: flex;
        justify-content: center;
        transform-origin: top;
    }

    .consultar-recebiveis-mobile{
        display: none;
    }
}

/*Responsivo-Mobile-L*/

@media (max-width: 425px) {
    .container-principal-mobile{
        margin: 0;
        padding: 0; 
    }

    .tabela-consulta{
        transform: scale(1.03);
    }

    .inputs{
        display: flex;
        flex-direction: column;
        margin-bottom: 45px;
    }

    .container-valores-mobile{
        transform: scale(0.98);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .valor-total-mobile{
        margin: 0;
        padding: 0;
        margin-left: 27px;
    }

    .container-lancamentos-mobile{
        transform: scale(0.99); 
        display: flex;
        flex-direction: column;
        justify-content: center;
        transform-origin: top;
    }

    .consultar-recebiveis{
        display: none;
    }

    .consultar-recebiveis-mobile{
        display: block;
    }

}


/*Responsivo-Mobile-M*/

@media (max-width: 375px) {
    .container-principal-mobile{
        margin: 0;
        padding: 0; 
    }

    .tabela-consulta{
        transform: scale(0.88);
    }

    .inputs{
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        margin-left: 12px;
        transform: scale(0.9);
    }

    .container-valores-mobile{
        transform: scale(0.88);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .valor-total-mobile{
        margin: 0;
        padding: 0;
        margin-left: 27px;
    }

    .container-lancamentos-mobile{
        transform: scale(1.0); 
        display: flex;
        flex-direction: column;
        justify-content: center;
        transform-origin: top;
    }

    .consultar-recebiveis{
        display: none;
    }

    .consultar-recebiveis-mobile{
        display: block;
    }

}

/*Responsivo-Mobile-S*/

@media (max-width: 320px) {
    .container-principal-mobile{
        margin: 0;
        padding: 0; 
    }

    .tabela-consulta{
        transform: scale(0.71);
    }

    .inputs{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        margin-left: 10px;
        width: 140px;
    }

    .container-valores-mobile{
        transform: scale(0.74);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .valor-total-mobile{
        margin: 0;
        padding: 0;
        margin-left: 27px;
    }

    .container-lancamentos-mobile{
        transform: scale(1.11); 
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    .consultar-recebiveis{
        display: none;
    }

    .consultar-recebiveis-mobile{
        display: block;
    }
}
