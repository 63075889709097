html, body {
  /* Height and width fallback for older browsers. */
 
}

.home{
  
  display: flex;
  overflow: auto;
  /*background: red; Just to visualize the extent*/
  background: #f1e2c4; /* Just to visualize the extent */
  height: 100%;
  width: 100%;

  /* Set the height to match that of the viewport. */
  height: 100vh;

  /* Set the width to match that of the viewport. */
  width: 100vw;

  /* Remove any browser-default margins. */
  margin: 0;
}
  
.fundo{
  width: 100px;
  height: 200px;
}