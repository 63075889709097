@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");

@font-face {
  font-family: 'Geist';
  src: url('./pages/assets/fonts/Geist/Geist-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./pages/assets/fonts/Geist/Geist-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./pages/assets/fonts/Geist/Geist-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./pages/assets/fonts/Geist/Geist-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./pages/assets/fonts/Geist/Geist-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./pages/assets/fonts/Geist/Geist-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./pages/assets/fonts/Geist/Geist-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./pages/assets/fonts/Geist/Geist-UltraBlack.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./pages/assets/fonts/Geist/Geist-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./pages/assets/fonts/Geist/Geist-Black.ttf') format('truetype');
  font-weight: 900; /* Use o peso apropriado */
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./pages/assets/fonts/Geist/Geist-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./pages/assets/fonts/Geist/Geist-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./pages/assets/fonts/Geist/Geist-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./pages/assets/fonts/Geist/Geist-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./pages/assets/fonts/Geist/Geist-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./pages/assets/fonts/Geist/Geist-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./pages/assets/fonts/Geist/Geist-UltraBlack.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./pages/assets/fonts/Geist/Geist-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

body, * {
  margin: 0;
  line-height: normal;
  font-family: 'Geist', sans-serif !important;
}

:root {
  /* fonts */
  --font-montserrat: Montserrat;

  /* font sizes */
  --font-size-xl: 20px;
  --font-size-5xl: 24px;
  --font-size-mini: 15px;
  --font-size-3xs: 10px;
  --font-size-base: 16px;

  /* Colors */
  --xulizcolors-branco: #fff;
  --xulizcolors-branco-c: #f1f1f1;
  --xulizcolors-preto-c: #3d3d3d;
  --xulizcolors-padro-xuliz-dark-xuliz: #262626;
  --xulizcolors-cinza-padro: #999;
  --xulizcolors-laranja: #e17e36;
  --xulizcolors-padro-xuliz-light-xuliz: #f2e2c4;

  /* Gaps */
  --gap-26xl: 45px;
  --gap-16xl: 35px;
  --gap-mini: 15px;
  --gap-6xl: 25px;
  --gap-base: 16px;

  /* Paddings */
  --padding-11xl: 30px;
  --padding-xl: 20px;
  --padding-mini: 15px;
  --padding-6xl: 25px;
  --padding-48xl: 67px;
  --padding-16xl: 35px;
  --padding-3xs: 10px;
  --padding-31xl: 50px;

  /* Border radiuses */
  --br-xl: 20px;
  --br-3xs: 10px;
  --br-mini: 15px;
}
